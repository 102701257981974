import React, { useState, useEffect } from 'react';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import TruncatedText from './TruncatedText';
import { normalizePath } from './utils';
import styles from './DropdownItem.module.css';

const DropdownItem = ({ 
  item, 
  level = 0, 
  onSelect, 
  isSelected, 
  multiSelect, 
  value,
  choosenShouldShow = 'label'  // Add choosenShouldShow prop
}) => {

  const [isExpanded, setIsExpanded] = useState(item.expanded || false);
  const hasChildren = item.children?.length > 0;

  const checkSelectedState = (item, value) => {
    const normalizedItemValue = normalizePath(item.value);
    const normalizedValue = normalizePath(value);
    
    if (normalizedItemValue === normalizedValue) return true;
    if (item.children) return item.children.some(child => checkSelectedState(child, value));
    return false;
  };

  useEffect(() => {
    if ((value && checkSelectedState(item, value)) || item.expanded) {
      setIsExpanded(true);
    }
  }, [item, value]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (hasChildren) setIsExpanded(!isExpanded);
    else onSelect(item.value);
  };

  const isItemSelected = multiSelect
    ? isSelected?.includes(normalizePath(item.value))
    : normalizePath(value) === normalizePath(item.value);

  const displayText = choosenShouldShow === 'value' ? item.value : item.label;

  return (
    <>
      <div
        className={`${styles.item} ${isItemSelected ? styles.selected : ''} 
        ${hasChildren ? styles.hasChildren : ''}`}
        style={{ paddingLeft: `${level * 1 + 1}rem` }}
        onClick={handleClick}
      >
        <div className={styles.content}>
          <TruncatedText text={displayText} className={styles.label} />
          {hasChildren && (
            <DropdownIconClosed className={`${styles.icon} ${isExpanded ? styles.expanded : ''}`} />
          )}
        </div>
      </div>
      {hasChildren && isExpanded && (
        <div className={styles.nested}>
          {item.children.map(child => (
            <DropdownItem
              key={child.value}
              item={child}
              level={level + 1}
              onSelect={onSelect}
              isSelected={multiSelect ? isSelected : isSelected}
              multiSelect={multiSelect}
              value={value}
              choosenShouldShow={choosenShouldShow}  // Pass down choosenShouldShow
            />
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownItem;