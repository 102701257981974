import React from 'react';
import { AlertCircle } from 'lucide-react';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import styles from './ValidationParam.module.css';

const dropdownStyle = {
    height: '34px',
    boxSizing: 'border-box',
    borderRadius: '8px'
  };

const ValidationParam = ({ param, value, onChange, error, isIncomplete }) => {
  if (param.type === 'boolean') {
    return (
      <div className={styles.paramField}>
        <CustomDropdown
          items={[
            { value: 'true', label: 'True' },
            { value: 'false', label: 'False' }
          ]}
          value={value?.toString() || ''}
          onChange={(val) => onChange(param.name, val === 'true')}
          placeholder={param.placeholder}
          style={{ ...dropdownStyle, minWidth: '12rem' }}
          className={isIncomplete ? 'incomplete' : ''}
        />
        {error && (
          <div className={styles.errorMessage}>
            <AlertCircle size={14} />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.paramField}>
      <input
        type={param.type}
        value={value || ''}
        onChange={(e) => onChange(param.name, e.target.value)}
        placeholder={param.placeholder}
        min={param.min}
        max={param.max}
        className={`${styles.input} ${isIncomplete ? styles.incomplete : ''}`}
      />
      {error && (
        <div className={styles.errorMessage}>
          <AlertCircle size={14} />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default ValidationParam;