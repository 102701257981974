import React from 'react';
import { getValidationAttributes } from '../../../../../utils/validationUtils';
import styles from './NumberField.module.css';

const NumberField = ({ field, value, onChange, error }) => {
    const validationAttributes = getValidationAttributes(field);

    return (
        <div className={styles.fieldContainer}>
            <label htmlFor={field.id} className={styles.label}>
                {field.label}
            </label>
            <input
                type="number"
                id={field.id}
                placeholder={field.placeholder}
                value={value}
                required={field.required}
                onChange={(e) => onChange(e.target.value)}
                className={styles.numberInput}
                {...validationAttributes}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default NumberField;
