import React, { memo } from "react";
import { Save, RotateCcw } from "lucide-react";
import styles from "./ActionBar.module.css";

const ActionBar = memo(({ onSave, onReset, disabled }) => (
  <div className={styles.actionBar}>
    <button 
      className={`${styles.button} ${styles.resetButton}`}
      onClick={onReset}
      disabled={disabled}
    >
      <RotateCcw size={16} />
      <span>Reset</span>
    </button>
    <button 
      className={`${styles.button} ${styles.saveButton}`}
      onClick={onSave}
      disabled={disabled}
    >
      <Save size={16} />
      <span>Save</span>
    </button>
  </div>
));

export default ActionBar;