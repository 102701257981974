export const normalizePath = (path) => {
    if (!path) return '';
    return path
      .toLowerCase()
      .replace(/name$/i, '')
      .split('.')
      .map(segment => segment.trim())
      .join('.');
  };
  
  export const searchInItems = (items, searchText) => {
    const searchLower = searchText.toLowerCase();
    
    const searchItem = (item) => {
      const matchesSearch = item.label.toLowerCase().includes(searchLower) ||
                           item.value.toLowerCase().includes(searchLower);
      
      if (item.children) {
        const matchingChildren = item.children
          .map(child => searchItem(child))
          .filter(Boolean);
        
        if (matchingChildren.length > 0) {
          return { ...item, children: matchingChildren, expanded: true };
        }
      }
      return matchesSearch ? item : null;
    };
  
    return items.map(item => searchItem(item)).filter(Boolean);
  };