import React from 'react';
import { getValidationAttributes } from '../../../../../utils/validationUtils';
import styles from './RadioField.module.css';

const RadioField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label className={styles.label} style={style.label}>{field.label}</label>
            <div className={styles.optionsContainer}>
                {field.options.map((option) => (
                    <label key={option.value} className={styles.radioOption} style={style.radioOption}>
                        <input
                            type="radio"
                            name={field.id}
                            value={option.value}
                            checked={value === option.value}
                            onChange={() => onChange(option.value)}
                            className={styles.radio}
                            style={style.radio}
                            {...validationAttributes}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default RadioField;
