import React, { useState, useEffect, useCallback, memo } from "react";
import Header from "./Header/Header";
import ActionBar from "./ActionBar/ActionBar";
import MappingFieldsList from "./MappingFieldsList/MappingFieldsList";
import { 
  initialState,
  createNewField,
  getSelectedMappingData,
  createMappingData,
  getMappingName,
  isValidMapping,
  handleFieldOperation,
  addField,
  deleteField,
  updateField,
  reorderFields
} from './helpers';
import styles from "./FieldMappingContent.module.css";

const FieldMappingContent = memo(({ 
  sourceNode,
  targetNode,
  mappingType,
  onSaveMapping
}) => {
  const [selectedMapping, setSelectedMapping] = useState('new');
  const [mappingFields, setMappingFields] = useState([]);
  const [initialMappingState, setInitialState] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);
  const [mappingName, setMappingName] = useState('New Mapping');

  // Get existing mappings from the source node
  const existingMappings = sourceNode?.mappings?.[mappingType] || [];

  useEffect(() => {
    setMappingFields([createNewField(0)]);
  }, []);

  const handleFieldOperations = useCallback(operation => 
    handleFieldOperation(operation, setHasChanges), []);

  const handleMappingTypeChange = useCallback((value) => {
    const { fields, name } = getSelectedMappingData(value, existingMappings);
    setSelectedMapping(value);
    setMappingFields(fields);
    setMappingName(name);
    setInitialState({ selectedMapping: value, fields: [...fields] });
    setHasChanges(false);
  }, [existingMappings]);

  const handleNameChange = useCallback((newName) => {
    setMappingName(newName);
    setHasChanges(true);
  }, []);

  const handleFieldMappingChange = useCallback((id, field, value) => {
    setMappingFields(prevFields => {
      const updatedFields = updateField(id, field, value, prevFields);
      setHasChanges(true);
      return updatedFields;
    });
  }, []);

  const handleSave = useCallback(() => {
    const mappingData = createMappingData(
      selectedMapping, 
      mappingName, 
      mappingFields,
      {
        sourceNode,
        targetNode,
        mappingType
      }
    );
    
    onSaveMapping?.(mappingData);
    console.log('-----save mapping data: ',mappingData);
    setInitialState({
      selectedMapping,
      fields: mappingFields
    });
    setHasChanges(false);

  }, [selectedMapping, mappingName, sourceNode, targetNode, mappingFields, mappingType, onSaveMapping]);

  const handleReset = useCallback(() => {
    setSelectedMapping(initialMappingState.selectedMapping);
    setMappingFields(initialMappingState.fields);
    setMappingName(getMappingName(selectedMapping, existingMappings));
    setHasChanges(false);
  }, [initialMappingState, selectedMapping, existingMappings]);

  const handleAddField = useCallback((position) => {
    setMappingFields(handleFieldOperations(prev => addField(prev, position)));
  }, [handleFieldOperations]);

  const handleDeleteField = useCallback((id) => {
    setMappingFields(handleFieldOperations(prev => deleteField(id, prev)));
  }, [handleFieldOperations]);

  const handleReorderFields = useCallback((newOrder) => {
    setMappingFields(handleFieldOperations(() => reorderFields(newOrder)));
  }, [handleFieldOperations]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <Header
            selectedMapping={selectedMapping}
            existingMappings={existingMappings}
            onMappingTypeChange={handleMappingTypeChange}
            onNameChange={handleNameChange}
            mappingName={mappingName}
            sourceNodeName={sourceNode?.data?.properties?.label}
            targetNodeName={targetNode?.data?.properties?.label}
          />
          <ActionBar 
            onSave={handleSave}
            onReset={handleReset}
            disabled={!hasChanges || !isValidMapping(mappingFields)}
          />
        </div>

        <div className={styles.mappingContainer}>
          <MappingFieldsList
            mappingFields={mappingFields}
            sourceNode={sourceNode}
            targetNode={targetNode}
            onFieldMappingChange={handleFieldMappingChange}
            onDelete={handleDeleteField}
            onReorder={handleReorderFields}
            onAdd={handleAddField}
            mappingType={mappingType}
          />
        </div>
      </div>
    </div>
  );
});

FieldMappingContent.displayName = 'FieldMappingContent';

export default FieldMappingContent;