import React, { useState, useCallback } from 'react';
import { HelpCircle, Trash2, Code } from 'lucide-react';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import ControlsSection from '../sharedHelpers/ControlsSection';
import styles from './ExpressionContent.module.css';

const ExpressionContent = ({ onClose, onSave, initialConfig }) => {
  const [state, setState] = useState({
    data: {
      expressions: initialConfig?.expressions || []
    },
    history: [{ expressions: initialConfig?.expressions || [] }],
    currentIndex: 0,
    hasChanges: false,
    validationAttempted: false,
    invalidFields: new Set()
  });

  const handleUpdateHistory = useCallback((newExpressions) => {
    setState(prev => {
      const newHistory = prev.history.slice(0, prev.currentIndex + 1);
      const newData = { expressions: newExpressions };
      return {
        ...prev,
        data: newData,
        history: [...newHistory, newData],
        currentIndex: newHistory.length,
        hasChanges: true
      };
    });
  }, []);

  const handleChange = useCallback((index, value) => {
    const newExpressions = [...state.data.expressions];
    newExpressions[index] = value;
    handleUpdateHistory(newExpressions);
    
    setState(prev => {
      const newInvalidFields = new Set(prev.invalidFields);
      if (value.trim()) {
        newInvalidFields.delete(index);
      }
      return {
        ...prev,
        invalidFields: newInvalidFields
      };
    });
  }, [state.data.expressions, handleUpdateHistory]);

  const handleDelete = useCallback((index) => {
    if (state.data.expressions.length > 1) {
      const newExpressions = state.data.expressions.filter((_, i) => i !== index);
      handleUpdateHistory(newExpressions);
      setState(prev => {
        const newInvalidFields = new Set(prev.invalidFields);
        newInvalidFields.delete(index);
        return {
          ...prev,
          validationAttempted: false,
          invalidFields: newInvalidFields
        };
      });
    }
  }, [state.data.expressions, handleUpdateHistory]);

  const handleAdd = useCallback(() => {
    const emptyFieldIndexes = state.data.expressions
      .map((expr, index) => (!expr.trim() ? index : -1))
      .filter(index => index !== -1);

    if (emptyFieldIndexes.length > 0) {
      setState(prev => ({
        ...prev,
        validationAttempted: true,
        invalidFields: new Set(emptyFieldIndexes)
      }));
      return;
    }
    handleUpdateHistory([...state.data.expressions, '']);
  }, [state.data.expressions, handleUpdateHistory]);

  const handleSave = useCallback(() => {
    const nonEmptyExpressions = state.data.expressions.filter(expr => expr.trim());
    if (nonEmptyExpressions.length > 0) {
      onSave?.({
        expressions: nonEmptyExpressions
      });
    }
  }, [state.data.expressions, onSave]);

  const handleUndo = useCallback(() => {
    if (state.currentIndex > 0) {
      setState(prev => ({
        ...prev,
        data: prev.history[prev.currentIndex - 1],
        currentIndex: prev.currentIndex - 1,
        hasChanges: true,
        validationAttempted: false,
        invalidFields: new Set()
      }));
    }
  }, [state.currentIndex]);

  const handleRedo = useCallback(() => {
    if (state.currentIndex < state.history.length - 1) {
      setState(prev => ({
        ...prev,
        data: prev.history[prev.currentIndex + 1],
        currentIndex: prev.currentIndex + 1,
        hasChanges: true,
        validationAttempted: false,
        invalidFields: new Set()
      }));
    }
  }, [state.currentIndex, state.history.length]);

  const hasValidExpressions = state.data.expressions.some(expr => expr.trim());
  const showEmptyState = state.data.expressions.length === 0;

  return (
    <div className={styles.container}>
      <div className={styles.contentSection}>
        <div className={styles.contentWrapper}>
          {!showEmptyState && (
            <div className={styles.header}>
              <div className={styles.title}>expression</div>
              <button className={styles.helpButton} title="Help with common examples">
                <HelpCircle size={24} />
              </button>
            </div>
          )}
          
          <div className={styles.expressionsContainer}>
            {showEmptyState ? (
              <div className={styles.emptyStateContainer}>
              <EmptyState 
                icon={Code}
                title="No expressions configured"
                message="Click the Add button to create an expression"
              />
              </div>
            ) : (
              state.data.expressions.map((expression, index) => (
                <div key={index} className={styles.expressionRow}>
                  <input
                    type="text"
                    value={expression}
                    onChange={(e) => handleChange(index, e.target.value)}
                    className={`${styles.expressionInput} ${
                      (state.validationAttempted && !expression.trim()) || 
                      state.invalidFields.has(index) ? styles.error : ''
                    }`}
                    placeholder="Enter expression"
                  />
                  {state.data.expressions.length > 1 && (
                    <button
                      onClick={() => handleDelete(index)}
                      className={styles.deleteButton}
                      title="Delete expression"
                    >
                      <Trash2 size={20} />
                    </button>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <ControlsSection
        onAdd={handleAdd}
        onUndo={handleUndo}
        onRedo={handleRedo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={state.currentIndex > 0}
        canRedo={state.currentIndex < state.history.length - 1}
        isDisabled={!state.hasChanges || !hasValidExpressions}
      />
    </div>
  );
};

export default ExpressionContent;