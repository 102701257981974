import React, { memo, useCallback } from "react";
import { Reorder } from "framer-motion";
import MappingFieldRow from "./MappingFieldRow/MappingFieldRow";
import TableHeader from "./MappingFieldRow/TableHeader/TableHeader";
import styles from "./MappingFieldsList.module.css";

const MappingFieldsList = memo(({
  mappingFields,
  sourceNode,
  targetNode,
  onFieldMappingChange,
  onDelete,
  onReorder,
  onAdd,
  mappingType
}) => {
  // Sort mapping fields by position
  const sortedMappingFields = [...mappingFields].sort((a, b) =>
    (a.position ?? Infinity) - (b.position ?? Infinity)
  );

  // Handle reordering with positions
  const handleReorder = useCallback((reorderedFields) => {
    // Update positions based on new order
    const fieldsWithNewPositions = reorderedFields.map((field, index) => ({
      ...field,
      position: index
    }));
    
    onReorder(fieldsWithNewPositions);
  }, [onReorder]);

  // Handle adding new field
  const handleAddField = useCallback(() => {
    const newPosition = sortedMappingFields.length;
    onAdd(newPosition);
  }, [sortedMappingFields.length, onAdd]);

  return (
    <div className={styles.container}>
      <TableHeader mappingType={mappingType} />
      <Reorder.Group
        axis="y"
        values={sortedMappingFields}
        onReorder={handleReorder}
        className={styles.fieldsList}
      >
        {sortedMappingFields.map((mappingField) => (
          <Reorder.Item
            key={mappingField.id}
            value={mappingField}
            dragListener={sortedMappingFields.length > 1}
          >
            <MappingFieldRow
              mappingField={mappingField}
              onFieldMappingChange={onFieldMappingChange}
              onDelete={() => onDelete(mappingField.id)}
              sourceNode={sourceNode}
              targetNode={targetNode}
              position={mappingField.position}
              mappingType={mappingType}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      
      <div className={styles.addButtonWrapper}>
        <button
          className={`${styles.addButton} ${
            sortedMappingFields.some(field => !field.sourceField || !field.targetField) ?
            styles.disabled : ''
          }`}
          onClick={handleAddField}
          disabled={sortedMappingFields.some(field => !field.sourceField || !field.targetField)}
        >
          + Add Field Mapping
        </button>
      </div>
    </div>
  );
});

export default MappingFieldsList;