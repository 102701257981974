import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Trash2, Info, ChevronDown } from 'lucide-react';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import ValidationParam from './ValidationParam';
import { VALIDATION_TYPES } from './validationHelpers';
import styles from './ValidationRow.module.css';

const dropdownStyle = {
  height: '34px',
  boxSizing: 'border-box',
  borderRadius: '8px'
};

const ValidationRow = ({ 
  validation, 
  validationTypeOptions, 
  errors = {}, 
  invalidFields,
  validationAttempted,
  onChange, 
  onDelete 
}) => {
  const validationType = VALIDATION_TYPES[validation.type];
  const isIncomplete = validationAttempted && invalidFields.has(validation.id);

  return (
    <motion.div 
      className={styles.validationWrapper}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
    >
      <div className={`${styles.validationContent} ${isIncomplete ? styles.incomplete : ''}`}>
        <div className={styles.typeSelect}>
          <CustomDropdown
            items={validationTypeOptions}
            value={validation.type}
            onChange={(value) => onChange(validation.id, 'type', value)}
            style={{ ...dropdownStyle, minWidth: '12rem' }}
            placeholder="Select validation type"
            className={`${styles.dropdown} ${isIncomplete ? styles.incomplete : ''}`}
            icon={<ChevronDown className={styles.dropdownIcon} size={16} />}
          />
          
          {/* {validationType?.description && (
            <motion.div 
              className={styles.infoIconWrapper}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <Info size={16} className={styles.infoIcon} />
              <motion.div 
                className={styles.tooltipText}
                initial={{ opacity: 0, scale: 0.95 }}
                whileHover={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.2 }}
              >
                {validationType.description}
              </motion.div>
            </motion.div>
          )} */}
        </div>

        <motion.div 
          className={styles.paramsContainer}
          layout
        >
          <AnimatePresence mode="popLayout">
            {validationType?.params.map(param => (
              <motion.div
                key={param.name}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 10 }}
                transition={{ duration: 0.2 }}
              >
                <ValidationParam
                  param={param}
                  value={validation.params[param.name]}
                  onChange={(name, value) => onChange(validation.id, name, value)}
                  error={errors[param.name]}
                  isIncomplete={isIncomplete}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => onDelete(validation.id)}
        className={styles.deleteButton}
        title="Delete validation"
      >
        <Trash2 size={16} />
      </motion.button>
    </motion.div>
  );
};

export default ValidationRow;