import React, { useState } from 'react';
import FieldRenderer from './FieldRenderer';
import ButtonRenderer from './ButtonRenderer';
import { validateField } from '../../../utils/validationUtils';
import styles from './DynamicForm.module.css';

const DynamicForm = ({ config }) => {
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});

    // Handles changes to form fields, including file inputs
    const handleChange = (id, value) => {
        // Update form values state; handle file inputs appropriately
        setFormValues(prevValues => ({
            ...prevValues,
            [id]: Array.isArray(value) && value[0] instanceof File ? value[0] : value,
        }));
        console.log('Updated form value for', id, ':', value);
    };

    // Validates all fields using the provided validation function
    const validateForm = () => {
        const newErrors = {};
        // Check each field and field group for validation errors
        [...config.fields, ...config.fieldGroups.flatMap(group => group.fields)].forEach(field => {
            const error = validateField(field, formValues[field.id]);
            if (error) newErrors[field.id] = error;
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handles the submission of the form
    const handleSubmit = () => {
        if (validateForm()) {
            console.log('Form data:', formValues);
        } else {
            console.log('Validation Errors:', errors);
        }
    };

    // Handles button actions such as submit, reset, and cancel
    const handleButtonClick = (button) => {
        switch(button.action) {
            case 'submit':
                handleSubmit();
                break;
            case 'reset':
                setFormValues({});
                setErrors({});
                break;
            case 'cancel':
                console.log('Form canceled');
                break;
            default:
                console.log('Unknown action:', button.action);
        }
    };

    return (
        <form id={config.formId} onSubmit={(e) => e.preventDefault()} className={styles.formContainer}>
            <h2>{config.title}</h2>
            <p>{config.description}</p>

            <div className={styles.fields}>
                {config.fields.sort((a, b) => a.position - b.position).map((field) => (
                    <div key={field.id} className={styles.fieldItem}>
                        <FieldRenderer
                            field={field}
                            value={formValues[field.id] || ''}
                            onChange={(value) => handleChange(field.id, value)}
                            error={errors[field.id]}
                        />
                    </div>
                ))}
            </div>

            {config.fieldGroups.sort((a, b) => a.position - b.position).map((group) => (
                <div key={group.name} className={`${styles.fieldGroup} ${styles.fieldItem}`}>
                    <h3 className={styles.groupTitle}>{group.name}</h3>
                    <div className={styles.gridContainer}>
                        {group.fields.map((field) => (
                            <FieldRenderer
                                key={field.id}
                                field={field}
                                value={formValues[field.id] || ''}
                                onChange={(value) => handleChange(field.id, value)}
                                error={errors[field.id]}
                            />
                        ))}
                    </div>
                </div>
            ))}

            <div className={styles.buttonGroupContainer}>
                {config.buttonGroups.sort((a, b) => a.position - b.position).map((group) => (
                    <div key={group.name} className={styles.buttonGroup}>
                        {group.buttons.map((button) => (
                            <ButtonRenderer
                                key={button.id}
                                button={button}
                                onClick={() => handleButtonClick(button)}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </form>
    );
};

export default DynamicForm;
