// ActionButtons.jsx
import React, { memo } from "react";
import { CheckCircle2, Trash2, Wand2, ArrowLeftRight, Code2, Type } from "lucide-react";
import styles from "./ActionButtons.module.css";

const ActionButtons = memo(({ 
  onDelete, 
  onTransform, 
  onLookup,
  onExpression,
  onStatic,
  onValidate,
  showTransform,
  showLookup,
  showExpression,
  showStatic,
  hasValidations
}) => (
  <div className={styles.actionButtons}>
    {showTransform && (
      <button
        className={styles.iconButton}
        onClick={onTransform}
        title="Edit Transformation"
      >
        <Wand2 className={styles.icon} />
      </button>
    )}
    {showLookup && (
      <button
        className={styles.iconButton}
        onClick={onLookup}
        title="Edit Lookup"
      >
        <ArrowLeftRight className={styles.icon} />
      </button>
    )}
    {showExpression && (
      <button
        className={styles.iconButton}
        onClick={onExpression}
        title="Edit Expression"
      >
        <Code2 className={styles.icon} />
      </button>
    )}
    {showStatic && (
      <button
        className={styles.iconButton}
        onClick={onStatic}
        title="Edit Static Value"
      >
        <Type className={styles.icon} />
      </button>
    )}
    <button
      className={`${styles.iconButton} ${hasValidations ? styles.hasValidations : ''}`}
      onClick={onValidate}
      title="Configure Validations"
    >
      <CheckCircle2 className={styles.icon} />
    </button>
    <button
      className={styles.iconButton}
      onClick={onDelete}
      title="Delete"
    >
      <Trash2 className={styles.icon} />
    </button>
  </div>
));

ActionButtons.displayName = 'ActionButtons';
export default ActionButtons;