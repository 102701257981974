import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ViewCompactAltOutlinedIcon from '@mui/icons-material/ViewCompactAltOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import MenuIcon from '@mui/icons-material/Menu'; // Menu button for mobile
import profileImage from '../assetts/images/profile-image.svg';
import { logoutRequest } from '../redux/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import './Sidebar.css';


const menuItems = [
  {id:0, text: 'Dashboard', path: '/dashboard', icon: <DashboardOutlinedIcon /> },
  {id:1, text: 'User Management', path: '/user-management', icon: <PeopleOutlinedIcon />,
    children: [
      {
        id: 1.1,
        text: 'Partner',
        path: '/user-management/partner',
        icon: <BusinessCenterOutlinedIcon />,
      },
      {
        id: 1.2,
        text: 'Customer',
        path: '/user-management/customer',
        icon: <TagFacesOutlinedIcon />,
      },
      {
        id: 1.3,
        text: 'Users',
        path: '/user-management/users',
        icon: <GroupOutlinedIcon />,
      },
    ],
  },
  {id:2, text: 'Reports', path: '/reports', icon: <ReportIcon /> },
  {id:3, text: 'Logs', path: '/logs', icon: <BusinessCenterOutlinedIcon /> },
  {id:4, text: 'Event Pro', path: '/event-pro', icon: <CalendarMonthOutlinedIcon /> },
  {id:5, text: 'My Templates', path: '/my-templates', icon: <ViewCompactAltOutlinedIcon /> },
  {id:6, text: 'Profile', path: '/profile', icon: <Person2OutlinedIcon /> },
  {id:7, text: 'Notifications', path: '/user-inbox', icon: <InboxOutlinedIcon /> },
  {id:8, text: 'Settings', path: '/settings', icon: <SettingsOutlinedIcon /> },
];


const Sidebar = ({ isHidden, toggleSidebar, sx }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const firstName = useSelector((state) => state.auth.userDetails?.first_name || '');
  const lastName = useSelector((state) => state.auth.userDetails?.last_name || '');
  const email = useSelector((state) => state.auth.userDetails?.email || '');
  const [selectedItem, setSelectedItem] = useState(0);
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.auth.refreshToken);
  const access = useSelector((state) => state.auth.accessToken);
  const [expanded, setExpanded] = useState(null); // Track expanded submenu
  
  // Update selectedIndex based on the current location (active route)
  useEffect(() => {
    const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
    setSelectedIndex(currentIndex);
  }, [location.pathname]); // Update when location changes

  const handleMenuClick = (event) => {

    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleDrawerToggle = () => {
    console.log("handleDrawerToggle  ");
    
    setMobileOpen(!mobileOpen);
  };


  const handleSideMenuClick = (index, path) => {
    setSelectedIndex(index);
    setExpanded(prev => (prev === index ? null : index)); // Toggle expanded state
    if (!menuItems[index].children) {
      navigate(path); // Navigate only if there are no children
    }
    // navigate(path);
  };
  const handleToggleSubMenu = (index) => {
    setExpanded(prevState => (prevState === index ? null : index)); // Toggle expanded submenu
  };
  const handleLogout = () => {
    console.log("~~~~ handleLogout  refreshToken .... ",refresh)
    // Close the menu before logging out
    handleMenuClose();
    // Dispatch the logout action
    dispatch(logoutRequest({refresh, access}));
    
};
  const drawer = (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Avatar sx={{ mr: 2 }} src={profileImage} alt="Profile Image" />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ color:'#000000', fontSize: '18px', letterSpacing: '0.01em', lineHeight: '21.78px', fontWeight: '700' }}>
              {firstName + " "+lastName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey', fontSize: '13px' }}>
              {email}
              {/* Administrator */}
            </Typography>
          </Box>
        </Box>


        <IconButton edge="end" aria-label="more" aria-controls="profile-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>


        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem sx={{color:'#000000'}} onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem sx={{color:'#000000'}} onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );


  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      onClose={handleDrawerToggle}
      sx={{ /* styles here */ }}
    >
      <Box sx={{
        width: '273px',
        position: 'fixed',
        top: 0,
        left: isHidden ? -273: 0,
        bottom: 0,
        borderRight: '1px solid #E8E8E8',  // Add a right border
        transition: 'left 0.3s ease', 
        // display: isHidden ? 'none' : 'block',
        ...sx,  // Ensure sx is applied to the Sidebar
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          
        </Box>

         <IconButton
        onClick={toggleSidebar}
        sx={{
          position: 'absolute',
          top: 0,
          right: -30, // Adjust position as needed
          backgroundColor: isHidden ? '#F5F9FF' : '#F5F9FF', // Dynamic background color
          color: '#7398C7',
          borderRadius: '1px', // Creates the oval shape
          borderTopRightRadius: '8px', // Top-right corner radius
          borderBottomRightRadius: '8px', // Bottom-right corner radius
          padding: '2px 2px', // Adjusts padding for oval shape
          border: '1px solid #ECEEF6', // Add the border with the specified color
          '&:hover': {
            backgroundColor: '#F5F9FF', // Hover effect
          },
          zIndex: 160, // Ensure it's above other elements
          width: 'auto', // Ensure the width is flexible for oval shape
        }}
      >
        <MenuIcon fontSize="medium" />
      </IconButton>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '15px' }}>
          <Avatar src={profileImage} alt="Profile Image" />
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left'  }}>
            <Typography variant="h6" sx={{marginLeft:'10px', color:'#000000', fontSize: '18px', letterSpacing: '0.01em', lineHeight: '21.78px', fontWeight: '700' }}>
              {firstName} {lastName}
            </Typography>
            <Typography variant="body2" sx={{ marginLeft:'10px',color: 'grey', fontSize: '13px' }}>
              {email}
             
            </Typography>
          </Box>
          <IconButton edge="end" aria-label="more" aria-controls="profile-menu" aria-haspopup="true" sx={{ mr: 1 }}  onClick={handleMenuClick}>
            <MoreHorizIcon />
          </IconButton>

          
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem sx={{color:'#000000'}} onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem sx={{color:'#000000'}} onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
        
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem button onClick={() => handleSideMenuClick(index, item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} sx={{
              fontFamily: 'Inter',
              fontSize: '18.97px',
              fontWeight: selectedIndex === index ? 700 : 400,
              lineHeight: '22.96px',
              letterSpacing: '0.01em',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: selectedIndex === index ? '#232323' : '#6B6B6B',
            }} />
                {item.children && (
                  expanded === index ? (
                    <ExpandLess sx={{ color: '#232323' }} />
                  ) : (
                    <ExpandMore sx={{ color: '#6B6B6B' }} /> 
                  )
                  )}
              </ListItem>

              {item.children && (
              <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map(subItem => (
                    <ListItem key={subItem.id} button sx={{ pl: 4 }} onClick={() => navigate(subItem.path)}>
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.text} sx={{
                        fontFamily: 'Inter',
                        fontSize: '18.97px',
                        fontWeight: selectedIndex === index ? 700 : 400,
                        lineHeight: '22.96px',
                        letterSpacing: '0.01em',
                        textAlign: 'left',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none',
                       
                        color: selectedIndex === index ? '#232323' : '#6B6B6B',
                      }}/>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};


export default Sidebar;