import React, { useState } from "react";
import { ArrowRightLeft, Plus, Trash2 } from "lucide-react";
import CustomDropdown from "../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import DynamicPopup from "../../../../components/reusableComponents/DynamicPopup/DynamicPopup";
import FieldMappingContent from "../FormContent/FieldMappingContent";
import styles from "./MappingForm.module.css";

const MappingForm = ({
  mappingType,
  mappingConfig,
  onMappingChange
}) => {
  const [showFieldMapping, setShowFieldMapping] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [mappingsArray, setMappingsArray] = useState([{}]);

  const isInputMapping = mappingType === 'input';
  const currentNode = isInputMapping ? mappingConfig.targetNode : mappingConfig.sourceNode;
  const connectedNodes = isInputMapping ? mappingConfig.sourceNodes : mappingConfig.targetNodes;

  const handleAddRow = () => {
    setMappingsArray([...mappingsArray, {}]);
  };

  const handleRemoveRow = (index) => {
    const updatedMappings = mappingsArray.filter((_, i) => i !== index);
    setMappingsArray(updatedMappings);
  };

  const handleNodeSelect = (value, index) => {
    const updatedMappings = [...mappingsArray];
    const field = isInputMapping ? 'source' : 'target';
    updatedMappings[index][field] = value;
    setMappingsArray(updatedMappings);
  };

  const getConnectedNode = (index) => {
    const mapping = mappingsArray[index];
    const nodeId = isInputMapping ? mapping.source : mapping.target;
    return connectedNodes.find(n => n.id === nodeId);
  };

  return (
    <div className={styles.container}>
      {mappingsArray.map((mapping, index) => {
        const connectedNodeId = isInputMapping ? mapping.source : mapping.target;
        const canMapFields = !!connectedNodeId;

        return (
          <div key={index} className={styles.mappingRow}>
            <div className={styles.mappingFields}>
              <div className={styles.field}>
                <label className={styles.label}>
                  {isInputMapping ? "Source Node" : "Source Node (Current)"}
                </label>
                {isInputMapping ? (
                  <CustomDropdown
                    items={connectedNodes.map((node) => ({
                      value: node.id,
                      label: node.data?.properties?.label || node.id,
                    }))}
                    value={connectedNodeId}
                    onChange={(e) => handleNodeSelect(e, index)}
                    style={{
                      borderRadius: "8px",
                      color: "#6B6B6B",
                      width: '100%',
                      height: '42px'
                    }}
                  />
                ) : (
                  <input
                    className={styles.input}
                    value={currentNode.data?.properties?.label || currentNode.id}
                    disabled
                  />
                )}
              </div>

              <div className={styles.iconWrapper}>
                <ArrowRightLeft className={styles.icon} />
              </div>

              <div className={styles.field}>
                <label className={styles.label}>
                  {isInputMapping ? "Target Node (Current)" : "Target Node"}
                </label>
                {isInputMapping ? (
                  <input
                    className={styles.input}
                    value={currentNode.data?.properties?.label || currentNode.id}
                    disabled
                  />
                ) : (
                  <CustomDropdown
                    items={connectedNodes.map((node) => ({
                      value: node.id,
                      label: node.data?.properties?.label || node.id,
                    }))}
                    value={connectedNodeId}
                    onChange={(e) => handleNodeSelect(e, index)}
                    style={{
                      borderRadius: "8px",
                      color: "#6B6B6B",
                      width: '100%',
                      height: '42px'
                    }}
                  />
                )}
              </div>
            </div>

            <div className={styles.actionsWrapper}>
              <button 
                className={`${styles.button} ${!canMapFields && styles.disabled}`}
                onClick={() => {
                  setSelectedRowIndex(index);
                  setShowFieldMapping(true);
                }}
                disabled={!canMapFields}
              >
                <ArrowRightLeft size={16} />
                <span>Map Fields</span>
              </button>

              {mappingsArray.length > 1 && (
                <button
                  onClick={() => handleRemoveRow(index)}
                  className={styles.removeButton}
                  title="Remove mapping"
                >
                  <Trash2 size={16} />
                </button>
              )}
            </div>
          </div>
        );
      })}

      <button
        onClick={handleAddRow}
        className={`${styles.addButton} ${mappingsArray.length === 1 && styles.marginRight0}`}
      >
        <Plus size={16} />
        Add Mapping
      </button>

      {showFieldMapping && (
        <DynamicPopup
          title={`Mapping [${
            isInputMapping
              ? `${getConnectedNode(selectedRowIndex)?.data?.properties?.label || 'Source'} → ${currentNode?.data?.properties?.label || 'Current'}`
              : `${currentNode?.data?.properties?.label || 'Current'} → ${getConnectedNode(selectedRowIndex)?.data?.properties?.label || 'Target'}`
          }]`}
          onClose={() => setShowFieldMapping(false)}
          draggable={false}
          style={{ minHeight: "30rem", minWidth: "70%", background: "#fff" }}
          expanded
        >
          <FieldMappingContent
            sourceNode={isInputMapping ? getConnectedNode(selectedRowIndex) : currentNode}
            targetNode={isInputMapping ? currentNode : getConnectedNode(selectedRowIndex)}
            mappingType={mappingType}
          />
        </DynamicPopup>
      )}
    </div>
  );
};

export default MappingForm;