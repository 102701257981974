import React, { useState, useRef, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

const TruncatedText = ({ text, className, component: Component = 'span' }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const { offsetWidth, scrollWidth } = textRef.current;
        setShowTooltip(scrollWidth > offsetWidth);
      }
    };
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [text]);

  const content = <Component ref={textRef} className={className}>{text}</Component>;
  if (!showTooltip) return content;

  return (
    <Tooltip
      title={text}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#1f2937',
            '& .MuiTooltip-arrow': { color: '#1f2937' },
            fontSize: '12px',
            padding: '8px 12px',
            maxWidth: '300px',
          },
        },
      }}
    >
      {content}
    </Tooltip>
  );
};

export default TruncatedText;