import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { X, Maximize2, Minimize2, GripHorizontal } from 'lucide-react';
import Draggable from 'react-draggable';
import { motion } from 'framer-motion';
import styles from './DynamicPopup.module.css';

const POPUP_ROOT_ID = 'popup-root-container';
let popupRoot = document.getElementById(POPUP_ROOT_ID);
if (!popupRoot) {
  popupRoot = document.createElement('div');
  popupRoot.id = POPUP_ROOT_ID;
  document.body.appendChild(popupRoot);
}

// Static z-index for each popup instance
const baseZIndex = 1000;

const DynamicPopup = memo(({ 
  title, 
  children, 
  onClose, 
  draggable = true, 
  style = {}, 
  expanded = false,
  className = '',
  showDragHandle = true
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);
  const initialRender = useRef(true);
  const zIndexRef = useRef(baseZIndex);

  // Center popup on initial render
  useEffect(() => {
    if (initialRender.current && popupRef.current) {
      const rect = popupRef.current.getBoundingClientRect();
      setPosition({
        x: Math.max(0, (window.innerWidth - rect.width) / 2),
        y: Math.max(0, (window.innerHeight - rect.height) / 2)
      });
      initialRender.current = false;
    }
  }, []);

  const handleDragStart = useCallback(() => {
    if (!isExpanded) setIsDragging(true);
  }, [isExpanded]);

  const handleDrag = useCallback((e, data) => {
    if (!isExpanded && popupRef.current) {
      const { clientWidth, clientHeight } = document.documentElement;
      const popup = popupRef.current.getBoundingClientRect();
      
      setPosition({
        x: Math.min(Math.max(data.x, 0), clientWidth - popup.width),
        y: Math.min(Math.max(data.y, 0), clientHeight - popup.height)
      });
    }
  }, [isExpanded]);

  const handleDragStop = useCallback(() => setIsDragging(false), []);
  const toggleExpand = useCallback(() => setIsExpanded(prev => !prev), []);

  const popupContent = (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={styles.overlay}
        style={{ zIndex: zIndexRef.current - 1 }}
        onClick={(e) => e.target === e.currentTarget && onClose()}
      />
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className={`${styles.popupContainer} ${className} ${isDragging ? styles.dragging : ''}`}
        style={{
          ...style,
          zIndex: zIndexRef.current,
          ...(isExpanded ? {
            position: 'fixed',
            top: '4.375rem',
            left: 0,
            width: '100vw',
            height: '100vh',
            transform: 'none',
            borderRadius: 0
          } : {
            position: 'fixed',
            transform: `translate(${position.x}px, ${position.y}px)`
          })
        }}
        data-expanded={isExpanded}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.dragHandle}>
            {showDragHandle && !isExpanded && (
              <div className={styles.gripIcon}>
                <GripHorizontal size={16} strokeWidth={1.5} />
              </div>
            )}
            <h4 className={styles.popupHeader}>{title}</h4>
          </div>
          <div className={styles.iconWrapper}>
            <button 
              className={styles.iconButton} 
              onClick={toggleExpand}
              title={isExpanded ? 'Compress' : 'Expand'}
              type="button"
            >
              {isExpanded ? (
                <Minimize2 size={18} strokeWidth={1.5} className={styles.actionIcon} />
              ) : (
                <Maximize2 size={18} strokeWidth={1.5} className={styles.actionIcon} />
              )}
            </button>
            <button 
              className={`${styles.iconButton} ${styles.closeButton}`}
              onClick={onClose}
              title="Close"
              type="button"
            >
              <X size={18} strokeWidth={1.5} className={styles.actionIcon} />
            </button>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {children}
        </div>
      </motion.div>
    </>
  );

  return createPortal(
    draggable && !isExpanded ? (
      <Draggable
        handle={`.${styles.headerWrapper}`}
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragStop}
        position={position}
        bounds="parent"
        scale={1}
      >
        {popupContent}
      </Draggable>
    ) : popupContent,
    popupRoot
  );
});

export default DynamicPopup;