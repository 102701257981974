// ReAuth.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reAuthenticationRequest } from './redux/actions/authActions';
import { isTokenExpired, isTokenValid, refreshAccessToken } from './utils/tokenUtils';

const ReAuth = () => {
  const dispatch = useDispatch();
  let accessToken = localStorage.getItem("accessToken");
//   let accessToken = useSelector((state) => state.auth.accessToken);
  useEffect(() => {    
    if (!isTokenValid(accessToken)) {
        accessToken = refreshAccessToken();
        if(isTokenValid(accessToken)){
            dispatch(reAuthenticationRequest(accessToken));
        }
    }else{
        dispatch(reAuthenticationRequest(accessToken));
    }
    
  }, [dispatch]);

  return null; // No UI, only dispatches action on mount
};

export default ReAuth;
