import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        padding: '16px',
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '64px', fontWeight: '700', color: '#232323' }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ marginTop: '16px', color: '#6B6B6B' }}>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        sx={{ marginTop: '24px', backgroundColor: '#1976d2', color: '#fff' }}
        onClick={() => navigate('/')}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default PageNotFound;
