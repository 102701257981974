import TransformationContent from "./TransformationContent/TransformationContent";
import LookupContent from "./LookupContent/LookupContent";
import ExpressionContent from "./ExpressionContent/ExpressionContent";
import ValidationConfig from "./ValidationConfig/ValidationConfig";
import StaticValueContent from "./StaticValueContent/StaticValueContent";

export const MAPPING_TYPES = {
  STANDARD: 'standard',
  STATIC: 'static',
  TRANSFORM: 'transform',
  LOOKUP: 'lookup',
  EXPRESSION: 'expression'
};

export const transformSchemaToDropdownFormat = (schema, parentKey = '') => {
  return Object.entries(schema).map(([key, value]) => {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const children = transformSchemaToDropdownFormat(value, currentKey);
      
      return {
        value: currentKey,
        label: key,
        children: children.length ? children : undefined
      };
    }
    
    return {
      value: currentKey,
      label: key
    };
  });
};

export const createDropdownConfigs = (sourceFields, targetFields) => [
  { 
    section: 'source',
    items: [
      { items: sourceFields, value: 'sourceField', maxWidth: '25vw', placeholder: "Select Source Field" },
      { items: [], value: 'sourceType', maxWidth: '16rem', placeholder: "Type" }
    ]
  },
  { 
    section: 'target',
    items: [
      { items: targetFields, value: 'targetField', maxWidth: '25vw', placeholder: "Select Target Field" },
      { items: [], value: 'targetType', maxWidth: '16rem', placeholder: "Type" }
    ]
  }
];

export const createHandleChange = (mappingField, onFieldMappingChange, setActivePopup) => (field) => (selectedValue) => {
  if (field === 'mappingType' && ['transform', 'lookup', 'expression', 'static'].includes(selectedValue)) {
    setActivePopup(selectedValue);
    return;
  }
  onFieldMappingChange(mappingField.id, field, selectedValue);
};

export const createHandleSave = (mappingField, onFieldMappingChange, setActivePopup) => (type) => (data) => {
  let fieldToUpdate = type;
  let valueToSave = data;

  switch(type) {
    case MAPPING_TYPES.TRANSFORM:
      fieldToUpdate = 'transformation';
      break;
    case MAPPING_TYPES.LOOKUP:
      fieldToUpdate = 'lookup';
      break;
    case MAPPING_TYPES.EXPRESSION:
      fieldToUpdate = 'expression';
      break;
    case MAPPING_TYPES.STATIC:
      fieldToUpdate = 'staticValue';
      valueToSave = data.staticValue;
      break;
    case 'validation':
      fieldToUpdate = 'validations';
      break;
  }

  onFieldMappingChange(mappingField.id, fieldToUpdate, valueToSave);
  
  if (type !== 'validation') {
    onFieldMappingChange(mappingField.id, 'mappingType', type);
    
    // Clear other mapping type fields
    [MAPPING_TYPES.TRANSFORM, MAPPING_TYPES.LOOKUP, MAPPING_TYPES.EXPRESSION, MAPPING_TYPES.STATIC].forEach(mappingType => {
      const fieldName = (() => {
        switch(mappingType) {
          case MAPPING_TYPES.TRANSFORM:
            return 'transformation';
          case MAPPING_TYPES.STATIC:
            return 'staticValue';
          default:
            return mappingType;
        }
      })();
      
      if (fieldName !== fieldToUpdate) {
        onFieldMappingChange(mappingField.id, fieldName, null);
      }
    });
  }

  setActivePopup(null);
};

export const createPopupContent = (mappingField) => ({
  [MAPPING_TYPES.TRANSFORM]: {
    title: "Define Transformation",
    Component: TransformationContent,
    props: {
      initialValue: mappingField.transformation?.initialValue || mappingField.sourceField,
      initialSteps: mappingField.transformation?.steps || []
    }
  },
  [MAPPING_TYPES.LOOKUP]: {
    title: "Define Lookup",
    Component: LookupContent,
    props: {
      initialConfig: mappingField.lookup || null
    }
  },
  [MAPPING_TYPES.EXPRESSION]: {
    title: "Define Expression",
    Component: ExpressionContent,
    props: {
      initialConfig: mappingField.expression || null
    }
  },
  [MAPPING_TYPES.STATIC]: {
    title: "Define Static Value",
    Component: StaticValueContent,
    props: {
      initialValue: mappingField.staticValue || ''
    }
  },
  validation: {
    title: "Configure Field Validation",
    Component: ValidationConfig,
    props: {
      initialConfig: mappingField.validations || null,
      fieldInfo: {
        sourceField: mappingField.sourceField,
        sourceType: mappingField.sourceType,
        targetField: mappingField.targetField,
        targetType: mappingField.targetType
      }
    }
  }
});

export const getActionButtonStates = (mappingField) => ({
  showTransform: mappingField.mappingType === MAPPING_TYPES.TRANSFORM,
  showLookup: mappingField.mappingType === MAPPING_TYPES.LOOKUP,
  showExpression: mappingField.mappingType === MAPPING_TYPES.EXPRESSION,
  showStatic: mappingField.mappingType === MAPPING_TYPES.STATIC,
  hasValidations: !!mappingField.validations?.length
});