export const dataTypeOptions = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'date', label: 'Date' },
  { value: 'object', label: 'Object' },
  { value: 'array', label: 'Array' }
];

export const mappingTypeOptions = [
  { value: 'standard', label: 'Standard' },
  { value: 'static', label: 'Static' },
  { value: 'transform', label: 'Transform' },
  { value: 'lookup', label: 'Lookup' },
  { value: 'expression', label: 'Expression' }
];

export const dropdownStyle = {
  height: '34px',
  boxSizing: 'border-box',
  borderRadius: '8px'
};