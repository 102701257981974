import { Box, Typography,Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Calendar from './calendar/Calendar';
import Schedule from './schedule/Scheduler';
import { useSelector } from 'react-redux';

const EventPro = () => {
  const [selectedFunction, setSelectedFunction] = useState('');
  // Dynamic list of sidebar items
  const sidebarItems = [
    { value: '1', label: 'Calendar' },
    { value: '2', label: 'Schedule' },
  ];
  const accessToken = useSelector((state) => state.auth.accessToken);
  console.log("Event Pro Index  accessToken... ",accessToken)
  // useEffect(()=>{
  //   console.log("selectedFunction ",selectedFunction)
  // },[selectedFunction])
  const renderContent = () => {
    switch (selectedFunction) {
      case '1':
        return <Calendar />
      case '2':
        return <Schedule/>
      default:
        return <Typography>Please select a function from the sidebar.</Typography>;
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        overflowX: "hidden",
      }}
    >
        <Header/>
        {/* Main layout with Sidebar and Content */}
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {/* Sidebar column - 2/12 (sm: 12, md: 3) */}
          <Grid item xs={12} md={2} marginTop={'73px'}>
            <Sidebar items={sidebarItems} onSelect={setSelectedFunction}/>
          </Grid>

          {/* Content column - 10/12 (sm: 12, md: 9) */}
          <Grid item xs={12} md={9}>
            {renderContent()}
          </Grid>
      </Grid>
    </Box>
  );
};

export default EventPro;