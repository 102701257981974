import { functionOptions } from './config';
import { validateField } from '../../../../../../../utils/validationUtils';

export const createNewStep = () => ({
  id: Date.now(),
  function: '',
  params: {},
});

export const getFunctionParams = (functionType) => {
  if (!functionType) return [];
  
  const selectedFunction = functionOptions?.find((func) => func?.value === functionType);
  return selectedFunction?.params || [];
};

export const validateSingleStep = (step) => {
  if (!step?.function) return false;
  
  const params = getFunctionParams(step.function);
  if (!params?.length) return true;
  
  return params.every((param) => {
    if (!param?.validation?.required) return true;
    
    const value = step?.params?.[param.name];
    return value !== undefined && value !== '' && value !== null;
  });
};

export const validateBeforeAdd = (steps) => {
  if (!Array.isArray(steps) || !steps.length) {
    return { canAdd: true };
  }
  
  const lastStep = steps[steps.length - 1];
  if (!lastStep) return { canAdd: true };
  
  const isLastStepIncomplete = !validateSingleStep(lastStep);
  
  return {
    canAdd: !isLastStepIncomplete,
    showValidation: isLastStepIncomplete,
  };
};

export const validateSteps = (steps, showValidation = false) => {
  if (!showValidation || !Array.isArray(steps)) {
    return { errors: {}, isValid: true };
  }

  const errors = {};
  let isValid = true;

  steps.forEach((step) => {
    if (!step?.id) return;
    
    const stepErrors = {};
    
    // Validate function field
    const functionError = validateField(
      { 
        label: 'Function', 
        validation: { required: true },
      },
      step?.function
    );
    
    if (functionError) {
      stepErrors.function = functionError;
      isValid = false;
    }

    // Validate params
    if (step?.function) {
      const params = getFunctionParams(step.function);
      params?.forEach((param) => {
        if (!param?.name) return;
        
        const error = validateField(param, step?.params?.[param.name]);
        if (error) {
          stepErrors[param.name] = error;
          isValid = false;
        }
      });
    }

    if (Object.keys(stepErrors).length > 0) {
      errors[step.id] = stepErrors;
    }
  });

  return { errors, isValid };
};

export const formatStepPreview = (step) => {
  if (!step?.function) return '';
  
  const params = getFunctionParams(step.function);
  if (!params?.length) return step.function;
  
  const formattedParams = params
    ?.map((param) => {
      if (!param?.name) return '';
      
      const value = step?.params?.[param.name];
      if (value == null || value === '') return '';
      
      if (Array.isArray(value)) return value.filter(Boolean).join(', ');
      if (typeof value === 'object') {
        try {
          return JSON.stringify(value);
        } catch {
          return '';
        }
      }
      return String(value);
    })
    .filter(Boolean)
    .join(', ');
  
  return `${step.function}(${formattedParams})`;
};

export const updateTransformationState = (
  newSteps,
  prevState,
  validationAttempted = false
) => {
  if (!Array.isArray(newSteps) || !prevState) {
    return prevState || {};
  }

  const validation = validateSteps(newSteps, validationAttempted);
  const newPreview = newSteps
    ?.map(formatStepPreview)
    .filter(Boolean)
    .join(' → ');

  const newHistory = prevState?.history || [];
  const currentIndex = prevState?.currentIndex ?? 0;

  return {
    ...prevState,
    steps: newSteps,
    history: [...newHistory.slice(0, currentIndex + 1), newSteps],
    currentIndex: currentIndex + 1,
    hasChanges: true,
    preview: newPreview || '',
    errors: validation?.errors || {},
    isValid: validation?.isValid ?? true,
    validationAttempted: !!validationAttempted,
  };
};

export const handleStepChange = (stepId, field, value, currentSteps) => {
  if (!stepId || !field || !Array.isArray(currentSteps)) return currentSteps;

  return currentSteps.map((step) => {
    if (!step?.id || step.id !== stepId) return step;

    if (field === 'function') {
      const params = getFunctionParams(value);
      const initializedParams = params?.reduce((acc, param) => {
        if (!param?.name || !param?.type) return acc;
        
        return {
          ...acc,
          [param.name]:
            param.type === 'select' && param.multiSelect
              ? []
              : param.type === 'group'
              ? {}
              : '',
        };
      }, {});

      return {
        ...step,
        function: value || '',
        params: initializedParams || {},
      };
    }

    return {
      ...step,
      params: {
        ...(step.params || {}),
        [field]: value,
      },
    };
  });
};

export const handleUndo = (currentState) => {
  if (!currentState?.history?.length || (currentState?.currentIndex ?? 0) <= 0) {
    return currentState;
  }
  
  const newIndex = (currentState.currentIndex || 0) - 1;
  const previousSteps = currentState.history[newIndex];
  
  if (!previousSteps) return currentState;

  return {
    ...currentState,
    steps: previousSteps,
    currentIndex: newIndex,
    preview: previousSteps
      ?.map(formatStepPreview)
      .filter(Boolean)
      .join(' → '),
    hasChanges: true,
  };
};

export const handleRedo = (currentState) => {
  if (
    !currentState?.history?.length ||
    (currentState?.currentIndex ?? 0) >= (currentState.history.length - 1)
  ) {
    return currentState;
  }
  
  const newIndex = (currentState.currentIndex || 0) + 1;
  const nextSteps = currentState.history[newIndex];
  
  if (!nextSteps) return currentState;

  return {
    ...currentState,
    steps: nextSteps,
    currentIndex: newIndex,
    preview: nextSteps
      ?.map(formatStepPreview)
      .filter(Boolean)
      .join(' → '),
    hasChanges: true,
  };
};

export const handleSave = (currentState, initialValue, onSave, onClose) => {
  if (!currentState?.steps) {
    return {
      success: false,
      error: 'No steps to save.',
    };
  }

  const validation = validateSteps(currentState.steps, true);
  
  if (!validation?.isValid) {
    return {
      success: false,
      errors: validation?.errors || {},
      error: 'Please fix all validation errors before saving.',
    };
  }

  try {
    onSave?.({
      steps: currentState.steps,
    });
    onClose?.();
    return { success: true };
  } catch (error) {
    return {
      success: false,
      error: error?.message || 'Error saving transformation. Please try again.',
    };
  }
};

export const handleDeleteStep = (stepId, currentSteps) => {
  if (!stepId || !Array.isArray(currentSteps)) return currentSteps;
  
  const newSteps = currentSteps.filter((s) => s?.id && s.id !== stepId);
  return newSteps.length ? newSteps : [];
};

export const createInitialState = (initialSteps) => {
  const validInitialSteps = Array.isArray(initialSteps) && initialSteps.length > 0
    ? initialSteps
        .filter((step) => step && typeof step === 'object')
        .map((step) => ({
          ...step,
          id: step?.id || Date.now(),
          function: step?.function || '',
          params: step?.params || {},
        }))
    : [];

  return {
    steps: validInitialSteps,
    history: [validInitialSteps],
    currentIndex: 0,
    hasChanges: false,
    preview: '',
    errors: {},
    isValid: true,
    validationAttempted: false,
  };
};