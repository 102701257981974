import React, { useState, useCallback, useMemo } from 'react';
import ControlsSection from '../sharedHelpers/ControlsSection';
import styles from './StaticValueContent.module.css';

const initializeState = (initialValue) => ({
  value: initialValue || '',
  history: [initialValue || ''],
  currentIndex: 0,
  hasChanges: false,
  validationAttempted: false,
  isValid: false
});

const StaticValueContent = ({ onClose, onSave, initialValue }) => {
  const [state, setState] = useState(() => initializeState(initialValue));

  const isValidData = useMemo(() => 
    state.value.trim().length > 0
  , [state.value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setState(prev => ({
      ...prev,
      value: newValue,
      history: [...prev.history.slice(0, prev.currentIndex + 1), newValue],
      currentIndex: prev.currentIndex + 1,
      hasChanges: true,
      validationAttempted: prev.validationAttempted,
      isValid: newValue.trim().length > 0
    }));
  };

  const handleUndo = useCallback(() => {
    if (state.currentIndex > 0) {
      setState(prev => ({
        ...prev,
        value: prev.history[prev.currentIndex - 1],
        currentIndex: prev.currentIndex - 1,
        hasChanges: true
      }));
    }
  }, [state.currentIndex]);

  const handleRedo = useCallback(() => {
    if (state.currentIndex < state.history.length - 1) {
      setState(prev => ({
        ...prev,
        value: prev.history[prev.currentIndex + 1],
        currentIndex: prev.currentIndex + 1,
        hasChanges: true
      }));
    }
  }, [state.currentIndex, state.history.length]);

  const handleSave = useCallback(() => {
    if (isValidData) {
      onSave?.({ staticValue: state.value });
    } else {
      setState(prev => ({
        ...prev,
        validationAttempted: true
      }));
    }
  }, [state.value, onSave, isValidData]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            Static Value <span className={styles.required}>*</span>
          </div>
        </div>
        
        <div className={styles.inputWrapper}>
          <input
            type="text"
            value={state.value}
            onChange={handleChange}
            className={`${styles.input} ${state.validationAttempted && !isValidData ? styles.inputError : ''}`}
            placeholder="Enter static value"
          />
        </div>
      </div>

      <ControlsSection
        onUndo={handleUndo}
        onRedo={handleRedo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={state.currentIndex > 0}
        canRedo={state.currentIndex < state.history.length - 1}
        isDisabled={!state.hasChanges || !isValidData}
      />
    </div>
  );
};

export default StaticValueContent;