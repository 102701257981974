import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import PartnerForm from '../../components/user-management/PartnerForm';
import PartnersList from '../../components/user-management/PartnersList';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';

const Partner = () => {
    const [newPartner, setNewPartner ] = useState (false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClickIntegration =()=>{
        setNewPartner(true);
    }
    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    return (
        <Box sx={{ p: 5, mt:8}}>
            <Button
                variant="contained"
                sx={{
                    alignItems:'left',
                    width: '160px',
                    height: '32px',
                    borderRadius: '24px',
                    backgroundColor: '#4F63BE',
                    boxShadow:'none',
                    textTransform: 'none',
                    '& .MuiButton-startIcon': {
                    borderRadius: '50%',
                    padding: '0px',
                    },
                }}
                startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%' }} />}
                onClick={handleOpen} // Open drawer on click
            >
            Create New
            </Button>
            
            <Box className="buttonGroup" display="flex" marginTop={5}>
                {newPartner ? (
                    <PartnerForm />
                ):(
                    <PartnersList />
                )}
                {/* Modal for PartnerForm */}
                    <Modal open={isModalOpen} onClose={handleClose}>
                        <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                height:'90vh',
                                width: '60vw',
                                borderRadius: 2,
                            }}
                        >
                        <Typography variant="h6" component="h2">
                            Create Partner
                        </Typography>
                        <IconButton
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        {/* Content of the modal */}
                        <PartnerForm />
                        </Box>
                    </Modal>
            </Box>
        </Box>
    );
};

export default Partner;
