// EmptyState.jsx
import React from 'react';
import { Info } from 'lucide-react';
import styles from './EmptyState.module.css';

const EmptyState = ({ 
  icon: Icon = Info,
  title = "No items found",
  message = "Get started by adding a new item",
  iconSize = 24,
  className = ""
}) => {
  return (
    <div className={`${styles.emptyState} ${className}`}>
      <Icon size={iconSize} />
      <p>{title}</p>
      <span>{message}</span>
    </div>
  );
};

export default EmptyState;