import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Info } from 'lucide-react';
import ControlsSection from '../sharedHelpers/ControlsSection';
import ValidationRow from './ValidationRow';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import { 
  validationTypeOptions,
  initializeState, 
  createValidationOperations, 
  updateHistory,
  validateValidation 
} from './validationHelpers';
import styles from './ValidationConfig.module.css';

const ValidationConfig = ({ onClose, onSave, initialConfig, fieldInfo }) => {
  // Initialize state with hasChanges based on initial config validity
  const [state, setState] = useState(() => {
    const initial = initializeState(initialConfig);
    if (!initialConfig) return initial;

    // Check if initial config has valid validations
    const hasValidValidations = initialConfig.every(validation => 
      !validateValidation(validation)
    );

    return {
      ...initial,
      hasChanges: hasValidValidations
    };
  });

  // Determine if data is valid
  const isValidData = useMemo(() => {
    if (state.validations.length === 0) return false;
    
    // Check if all validations are valid
    return state.validations.every(validation => 
      !validateValidation(validation)
    );
  }, [state.validations]);

  const handleUpdateHistory = useCallback((newValidations) => {
    updateHistory(setState, newValidations);
  }, []);

  const validationOperations = useMemo(() => 
    createValidationOperations(
      state, 
      setState, 
      handleUpdateHistory, 
      onSave
    ),
    [state, handleUpdateHistory, onSave]
  );

  // Compare current validations with initial config to determine changes
  useEffect(() => {
    if (!initialConfig) return;

    // Strip IDs for comparison since they may differ
    const normalizeValidation = (v) => ({
      type: v.type,
      params: v.params
    });

    const currentValidations = state.validations.map(normalizeValidation);
    const initialValidations = initialConfig.map(normalizeValidation);

    const hasChanged = JSON.stringify(currentValidations) !== JSON.stringify(initialValidations);
    
    if (hasChanged !== state.hasChanges) {
      setState(prev => ({
        ...prev,
        hasChanges: hasChanged
      }));
    }
  }, [state.validations, initialConfig]);

  return (
    <div className={styles.container}>
      <div className={styles.contentSection}>
        <div className={styles.contentWrapper}>
          {state.validations.length === 0 ? (
            <EmptyState 
              icon={Info}
              title="No validations configured"
              message="Click the Add button to create a validation rule"
              className={styles.emptyState}
            />
          ) : (
            <div className={styles.validationsContainer}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  Validation Rules <span className={styles.required}>*</span>
                </div>
                <div className={styles.headerSubtitle}>
                  Configure validation rules for this field
                </div>
              </div>

              {state.validations.map((validation) => (
                <ValidationRow
                  key={validation.id}
                  validation={validation}
                  validationTypeOptions={validationTypeOptions}
                  errors={state.errors[validation.id]}
                  invalidFields={state.invalidFields}
                  validationAttempted={state.validationAttempted}
                  onChange={validationOperations.change}
                  onDelete={validationOperations.delete}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <ControlsSection
        onAdd={validationOperations.add}
        onUndo={validationOperations.undo}
        onRedo={validationOperations.redo}
        onSave={validationOperations.save}
        onClose={onClose}
        canUndo={state.currentIndex > 0}
        canRedo={state.currentIndex < state.history.length - 1}
        isDisabled={!isValidData || (!state.hasChanges && initialConfig)}
      />
    </div>
  );
};

export default ValidationConfig;