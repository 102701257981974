// StepRow.jsx
import React, { useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Trash2, Info, AlertCircle, MoveRight } from 'lucide-react';
import { functionOptions } from './config';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import { getValidationAttributes } from '../../../../../../../utils/validationUtils';
import styles from './StepRow.module.css';
import { Widgets } from '@mui/icons-material';

const dropdownStyles = {
  height: '38px',
  boxSizing: 'border-box',
  borderRadius: '8px',
};

const ValidationMessage = memo(({ message, error }) => {
  if (!message) return null;
  
  const Icon = error ? AlertCircle : Info;
  
  return (
    <div className={`${styles.validationMessage} ${error ? styles.errorMessage : styles.warningMessage}`}>
      <Icon size={12} className={styles.validationIcon} />
      <p className={styles.validationText}>{message}</p>
    </div>
  );
});

const Field = memo(({ 
  param, 
  value, 
  onChange, 
  validationState
}) => {
  const { type, placeholder, options, multiSelect } = param;
  const isRequired = param.validation?.required;
  const hasError = Boolean(validationState?.error);
  const className = `${styles.fieldInput} ${hasError ? styles.errorField : ''} ${isRequired ? styles.requiredField : ''}`;
  const validationAttributes = getValidationAttributes(param);

  const getDropdownStyles = (hasError) => ({
    ...dropdownStyles,
    borderColor: hasError ? '#EF4444' : undefined
  });

  const handleComplexValue = (val) => {
    if (Array.isArray(val)) return val.join(', ');
    if (typeof val === 'object') return JSON.stringify(val);
    return val;
  };

  const renderField = () => {
    if (type === 'select') {
      const displayValue = handleComplexValue(value);
      
      return (
        <CustomDropdown
          items={options}
          value={displayValue}
          multiSelect={multiSelect}
          onChange={onChange}
          placeholder={placeholder}
          style={getDropdownStyles(hasError)}
          className={className}
          aria-invalid={hasError}
          aria-required={isRequired}
          {...validationAttributes}
        />
      );
    }

    if (type === 'group') {
      return (
        <textarea
          value={typeof value === 'object' ? JSON.stringify(value, null, 2) : value || ''}
          onChange={(e) => {
            try {
              const parsed = JSON.parse(e.target.value);
              onChange(parsed);
            } catch (err) {
              onChange(e.target.value);
            }
          }}
          placeholder={placeholder}
          className={`${styles.textarea} ${className}`}
          rows={4}
          aria-invalid={hasError}
          aria-required={isRequired}
          {...validationAttributes}
        />
      );
    }

    return (
      <input
        type={type}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={className}
        aria-invalid={hasError}
        aria-required={isRequired}
        {...validationAttributes}
      />
    );
  };

  return (
    <div className={styles.fieldContainer}>
      {renderField()}
      <div className={styles.validationContainer}>
        <ValidationMessage 
          message={validationState?.error || validationState?.warning} 
          error={Boolean(validationState?.error)}
        />
      </div>
    </div>
  );
});

const FieldLabel = memo(({ label, isRequired }) => (
  <label className={styles.fieldLabel}>
    {label}
    {isRequired && <span className={styles.required}>*</span>}
  </label>
));

const StepRow = memo(({ 
  step, 
  index, 
  onStepChange, 
  onDelete, 
  params = [], 
  errors = {},
  saveError = null,
  showValidation = false,
  isIncomplete = false
}) => {
  const handleFieldChange = useCallback((name, value) => {
    onStepChange(step.id, name, value);
  }, [step.id, onStepChange]);

  const handleFunctionChange = useCallback((value) => {
    onStepChange(step.id, 'function', value);
  }, [step.id, onStepChange]);

  const handleDelete = useCallback(() => {
    onDelete(step.id);
  }, [step.id, onDelete]);

  const getValidationState = useCallback((fieldName) => {
    if (!showValidation || !isIncomplete) return {};
    return {
      error: errors[fieldName] || (fieldName === 'function' ? saveError : undefined),
      warning: undefined
    };
  }, [errors, saveError, showValidation, isIncomplete]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2 }}
      className={`${styles.row} ${showValidation && isIncomplete ? styles.incomplete : ''}`}
    >
      <div className={styles.number}>{index + 1}</div>

      <div className={styles.content}>
        <div className={styles.functionWrapper}>
          <FieldLabel label="Function" isRequired={true} />
          <Field
            param={{
              name: 'function',
              type: 'select',
              options: functionOptions,
              placeholder: 'Select Function',
              validation: { required: true }
            }}
            value={step.function}
            onChange={handleFunctionChange}
            validationState={getValidationState('function')}
          />
        </div>

        <div className={styles.fieldsWrapper}>
          {step.function && params?.length > 0 && params.map((param) => (
            <div key={param.name} className={styles.fieldGroup}>
              <FieldLabel 
                label={param.label} 
                isRequired={param.validation?.required} 
              />
              <Field
                param={param}
                value={step?.params[param.name]}
                onChange={(value) => handleFieldChange(param.name, value)}
                validationState={getValidationState(param.name)}
              />
            </div>
          ))}
        </div>
      </div>

      <button
        type="button"
        onClick={handleDelete}
        className={styles.deleteBtn}
        title="Delete step"
      >
        <Trash2 size={16} />
      </button>
    </motion.div>
  );
});

StepRow.displayName = 'StepRow';
Field.displayName = 'Field';
ValidationMessage.displayName = 'ValidationMessage';
FieldLabel.displayName = 'FieldLabel';

export default StepRow;