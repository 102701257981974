import { fetchApi } from "../utils/apiUtils";

const BASE_URL = process.env.REACT_APP_API_URL+ '/api/';

// API call function using fetch
export const fetchPartnersApi = async(accessToken, page)=> {
  
  const response = await fetchApi('partner/list/', 
    {
      method:'GET', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`, // Explicitly add token
    },
     requiresAuth:true
    }
  );
    // const response = await fetch(`${BASE_URL}partner/list/`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${accessToken}`,
    //     },
    // });

    // if (!response.ok) {
    //     throw new Error('Failed to fetch users details');
    // }

    return await response;

}
export const fetchCustomersApi = async(accessToken, page)=> {
  const response = await fetchApi('partner/list/', 
    {
      method:'GET', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`, // Explicitly add token
    },
     requiresAuth:true
    }
  );
    // const response = await fetch(`${BASE_URL}partner/list/`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${accessToken}`,
    //     },
    // });

    // if (!response.ok) {
    //     throw new Error('Failed to fetch users details');
    // }

    return await response

}
export const fetchUsersApi = async(accessToken, page)=> {
  const response = await fetchApi('user/list/', 
    {
      method:'POST', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`, // Explicitly add token
    },
     requiresAuth:true
    }
  );
    // const response = await fetch(`${BASE_URL}user/list/`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${accessToken}`,
    //     },
    // });

    // if (!response.ok) {
    //     throw new Error('Failed to fetch users details');
    // }

    return await response

}

export const createPartnerApi = async (accessToken, partnerData) =>{
    try {
      const response = await fetchApi('partner/create/', 
        {
          method:'POST', 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`, // Explicitly add token
          },
          body: JSON.stringify(partnerData),
          requiresAuth:true
        }
      );
      if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
      console.log('API Response:', result);
      return result;
    } catch (error) {

    }
    //     console.error('Error submitting partner data:', error);
    // console.log('accessToken ',accessToken, ' partnerData .. ',partnerData)
    //     const response = await fetch(`${BASE_URL}partner/create/`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${accessToken}`,
    //       },
    //       body: JSON.stringify(partnerData), // Convert the data to JSON
    //     });
  
    //     if (!response.ok) {
    //       throw new Error(`Error: ${response.status} - ${response.statusText}`);
    //     }
  
    //     const result = await response.json();
    //     console.log('API Response:', result);
    //     return result;
    //   } catch (error) {
    //     console.error('Error submitting partner data:', error);
    //   } finally {
    //     // setLoading(false); // Hide loading indicator
    //   }

}
export const createCustomerApi = async (accessToken, CustomerData) =>{
    //try {
    console.log('accessToken ',accessToken, '  Customer Data .. ',CustomerData)
        const response = await fetch(`${BASE_URL}customer/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify(CustomerData), // Convert the data to JSON
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
  
        const result = await response.json();
        console.log('API Response:', result);
        return result;
    //   } catch (error) {
    //     console.error('Error submitting CustomerData data:', error);
    //   } finally {
    //     // setLoading(false); // Hide loading indicator
    //   }

}
export const createUsersApi = async (accessToken, UsersData) =>{
    //try {
    console.log('accessToken ',accessToken, '  Users Data .. ',UsersData)
        const response = await fetch(`${BASE_URL}Users/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify(UsersData), // Convert the data to JSON
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
  
        const result = await response.json();
        console.log('API Response:', result);
        return result;
    //   } catch (error) {
    //     console.error('Error submitting CustomerData data:', error);
    //   } finally {
    //     // setLoading(false); // Hide loading indicator
    //   }

}