export const functionOptions = [
  {
    label: 'Remove Character',
    value: 'removeChar',
    params: [
      {
        name: 'position',
        label: 'Position',
        type: 'select',
        placeholder: 'Select position',
        multiSelect: false,
        options: [
          { value: 'Start', label: 'From Start' },
          { value: 'End', label: 'From End' },
          { value: 'Middle', label: 'From Middle' },
          { value: 'Any', label: 'Any Position' }
        ],
        validation: {
          required: true,
        },
        order: 1
      },
      {
        name: 'character',
        label: 'Character',
        type: 'text',
        placeholder: 'Enter character',
        validation: {
          required: true,
          maxLength: 1,
          pattern: '^[a-zA-Z0-9\\s-_]$'
        },
        order: 2
      },
      {
        name: 'extraCharacter',
        label: 'Additional Character',
        type: 'text',
        placeholder: 'Enter additional character',
        validation: {
          required: false,
          maxLength: 1,
          pattern: '^[a-zA-Z0-9\\s-_]$'
        },
        order: 3
      }
    ]
  },
  {
    label: 'Date Conversion',
    value: 'dateConversion',
    params: [
      {
        name: 'sourceFormat',
        label: 'From Format',
        type: 'select',
        multiSelect: false,
        options: [
          { value: '%d%m%', label: 'DD/MM/YYYY' },
          { value: 'UTC', label: 'UTC' }
        ],
        validation: {
          required: true
        },
        order: 1
      },
      {
        name: 'targetFormat',
        label: 'To Format',
        type: 'select',
        multiSelect: false,
        options: [
          { value: 'dd/MM/yyyy', label: 'DD/MM/YYYY' },
          { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
          { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
          { value: 'dd MMM yyyy', label: 'DD MMM YYYY' },
          { value: 'dd-MM-yyyy', label: 'DD-MM-YYYY' }
        ],
        validation: {
          required: true
        },
        order: 2
      },
      {
        name: 'timezone',
        label: 'Timezone',
        type: 'text',
        placeholder: 'Enter timezone',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 50,
          pattern: '^[A-Za-z/_+-]+$'
        },
        order: 3
      }
    ]
  },
  {
    label: 'System Date',
    value: 'systemDate',
    params: [
      {
        name: 'format',
        label: 'Format',
        type: 'select',
        multiSelect: false,
        options: [
          { value: 'dd/MM/yyyy', label: 'DD/MM/YYYY' },
          { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
          { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
          { value: 'dd MMM yyyy', label: 'DD MMM YYYY' },
          { value: 'dd-MM-yyyy', label: 'DD-MM-YYYY' }
        ],
        validation: {
          required: true
        },
        order: 1
      }
    ]
  },
  {
    label: 'Padding',
    value: 'padding',
    params: [
      {
        name: 'position',
        label: 'Position',
        type: 'select',
        multiSelect: false,
        options: [
          { value: 'Start', label: 'Start' },
          { value: 'End', label: 'End' }
        ],
        validation: {
          required: true
        },
        order: 1
      },
      {
        name: 'character',
        label: 'Character',
        type: 'text',
        placeholder: 'Enter padding character',
        validation: {
          required: true,
          maxLength: 1,
          pattern: '^[a-zA-Z0-9\\s-_]$'
        },
        order: 2
      },
      {
        name: 'length',
        label: 'Length',
        type: 'number',
        placeholder: 'Enter padding length',
        validation: {
          required: true,
          min: 1,
          max: 100,
          pattern: '^[0-9]+$'
        },
        order: 3
      }
    ]
  }
].sort((a, b) => a.label.localeCompare(b.label));