// LookupContent.jsx
import React, { useState, useCallback, useMemo } from 'react';
import { Trash2, MoveRight, ArrowLeftRight } from 'lucide-react';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import ControlsSection from '../sharedHelpers/ControlsSection';
import { initializeState, createStepOperations, updateHistory } from './lookupHelpers';
import styles from './LookupContent.module.css';

const LookupRow = ({ step, index, onDelete, onChange, showError }) => (
  <div className={styles.stepRow}>
    <div className={styles.inputWrapper}>
      <input
        type="text"
        value={step.source}
        onChange={(e) => onChange(index, 'source', e.target.value)}
        className={`${styles.input} ${showError ? styles.inputError : ''}`}
        placeholder="Enter source value"
      />
    </div>
    <MoveRight size={18} className={styles.arrow} />
    <div className={styles.inputGroup}>
      <input
        type="text"
        value={step.destination}
        onChange={(e) => onChange(index, 'destination', e.target.value)}
        className={`${styles.input} ${showError ? styles.inputError : ''}`}
        placeholder="Enter destination value"
      />
      <button
        onClick={() => onDelete(index)}
        className={styles.deleteButton}
        title="Delete step"
      >
        <Trash2 size={16} />
      </button>
    </div>
  </div>
);

const LookupContent = ({ onClose, onSave, initialConfig }) => {
  const [state, setState] = useState(() => initializeState(initialConfig));

  const handleUpdateHistory = useCallback((newSteps) => {
    updateHistory(setState, newSteps);
  }, []);

  const isValidData = useMemo(() => 
    state.steps.every(step => step.source?.trim() && step.destination?.trim())
  , [state.steps]);

  const handleSave = useCallback(() => {
    if (isValidData) {
      onSave?.({ steps: state.steps });
    }
  }, [state.steps, onSave, isValidData]);

  const { add, undo, redo, change, delete: deleteStep } = createStepOperations(
    state, 
    setState, 
    handleUpdateHistory, 
    handleSave
  );

  const shouldShowError = useCallback((step, index) => (
    state.validationAttempted && 
    state.invalidFields.has(index) && 
    (!step.source?.trim() || !step.destination?.trim())
  ), [state.validationAttempted, state.invalidFields]);

  if (state.steps.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <EmptyState 
            icon={ArrowLeftRight}
            title="No steps configured"
            message="Click the Add button to create a step"
          />
        </div>
        <ControlsSection
          onAdd={add}
          onUndo={undo}
          onRedo={redo}
          onSave={handleSave}
          onClose={onClose}
          canUndo={state.currentIndex > 0}
          canRedo={state.currentIndex < state.history.length - 1}
          preview={state.preview}
          isDisabled={!state.hasChanges || !isValidData}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            Source Values <span className={styles.required}>*</span>
          </div>
          <div className={styles.headerTitle}>
            Destination Values <span className={styles.required}>*</span>
          </div>
        </div>
        
        <div className={styles.steps}>
          {state.steps.map((step, index) => (
            <LookupRow
              key={index}
              step={step}
              index={index}
              onDelete={deleteStep}
              onChange={change}
              showError={shouldShowError(step, index)}
            />
          ))}
        </div>
      </div>

      <ControlsSection
        onAdd={add}
        onUndo={undo}
        onRedo={redo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={state.currentIndex > 0}
        canRedo={state.currentIndex < state.history.length - 1}
        preview={state.preview}
        isDisabled={!state.hasChanges || !isValidData}
      />
    </div>
  );
};

export default LookupContent;