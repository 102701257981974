// src/components/users/UserList.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import { fetchUsersRequest } from '../../redux/reducers/userReducer'
import { fetchCustomersApi } from '../../api/usermanagmentApi';
// import CustomTooltip from '../reusableComponents/CustomTooltip/CustomTooltip';
import CustomTooltip from '../reusableComponents/CustomTooltip/CustomTooltip';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import theme from '../../theme';
import { isTokenValid, refreshAccessToken } from '../../utils/tokenUtils';
import { reAuthenticationRequest } from '../../redux/actions/authActions';
import Preloader from '../common/Preloader';
const CustomersList = () => {
    const [usersData, setUsersData] = useState([]);
    const dispatch = useDispatch();
    let accessToken = useSelector ((state) => state.auth.accessToken);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
//   const {data, users, loading, error } = useSelector((state) => state.users);
    // console.log("... users... ",users)
//   useEffect(() => {
//     dispatch(fetchUsersRequest());
//   }, [dispatch]);

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>Error: {error}</p>;
//   }
useEffect(() => {
    const getUsersData = async () => {
      try {
        if (!isTokenValid(accessToken)) {
          accessToken = refreshAccessToken();
          if(accessToken) dispatch(reAuthenticationRequest(accessToken));
        }
        const data = await fetchCustomersApi(accessToken, 1); // Call the API to fetch templates
        setUsersData(data.results); // Set the 'results' array from the response
        console.log('data.workflow_count ',data.results)
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };

    getUsersData(); // Fetch templates when the component mounts
  }, [accessToken]);
 
    
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    };
  
    if (loading) {
      return <Preloader />; // Show preloader while loading
    }
   return (
    <Grid container spacing={2} sx={{ padding: 2, borderBottom: 'none'  }}>
      {/* Tile */}
      <Grid item xs={12} sx={{ padding: 2, backgroundColor: 'transparent', borderBottom: 'none'   }}>
        <Paper elevation={1} sx={{ 
            padding: 2, 
            backgroundColor: 'transparent', 
            border: 'none', // Ensures no border
            boxShadow: 'none', // Removes shadow
          }}>
          <Typography variant="h5" gutterBottom  sx={{
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: 600,
            color: '#000000', // Explicitly setting the font color to black
          }}>
            Customers List
          </Typography>
          
        </Paper>
      </Grid>

      {/* Table */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell sx={{ ...theme.typography.tableCellHeader }}>Serial No</TableCell>
               <TableCell sx={{ ...theme.typography.tableCellHeader }}>Name</TableCell>
               <TableCell sx={{ ...theme.typography.tableCellHeader }}>Business name</TableCell>
               <TableCell sx={{ ...theme.typography.tableCellHeader }}>Email</TableCell>
               <TableCell sx={{ ...theme.typography.tableCellHeader }}>Created date</TableCell>
               <TableCell sx={{ ...theme.typography.tableCellHeader }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell  sx={{ borderBottom: 'none' }}>{index+1}</TableCell>
                  <TableCell  sx={{ borderBottom: 'none' }}>{row.name}</TableCell>
                  <TableCell  sx={{ borderBottom: 'none' }}>{row.business_name} </TableCell>
                  <TableCell  sx={{ borderBottom: 'none' }}>{row.email_id} </TableCell>
                  <TableCell  sx={{ borderBottom: 'none' }}> {formatDate(row.created_at)}</TableCell>
                  <TableCell  sx={{ borderBottom: 'none', padding: '4px 8px', ml: 5 , display: 'flex',alignItems: 'center', flexDirection: 'row',marginLeft: 0}}>
                   <CustomTooltip title="Edit/View">
                     <IconButton
                    //   onClick={() => handleTimer(user.id)}
                      sx={{
                        backgroundColor: '#F2F2F2',
                        color: '#4F63BE',
                        mt:1,
                        mr: 2,
                        p: 1,
                        '&:hover': {
                          backgroundColor: '#D9D9D9',
                        },
                      }}
                    >
                      <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </CustomTooltip>
                  
                  <CustomTooltip title="View Customers">
                    <IconButton
                    //   onClick={() => handleTimer(user.id)}
                      sx={{
                        backgroundColor: '#F2F2F2',
                        color: '#4F63BE',
                        mt:1,
                        mr: 2,
                        p: 1,
                        '&:hover': {
                          backgroundColor: '#D9D9D9',
                        },
                      }}
                      
                    >
                      <PersonOutlineOutlinedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </CustomTooltip>
                  <CustomTooltip title="View Users">
                    <IconButton
                    //   onClick={() => handleTimer(user.id)}
                      sx={{
                        backgroundColor: '#F2F2F2',
                        color: '#4F63BE',
                        mt:1,
                        mr: 2,
                        p: 1,
                        '&:hover': {
                          backgroundColor: '#D9D9D9',
                        },
                      }}
                      
                    >
                      <PeopleOutlinedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </CustomTooltip>
                  <CustomTooltip title="Deactivate">
                    <IconButton
                    //   onClick={() => handleDelete(template.id)}
                      sx={{
                        backgroundColor: '#F2F2F2',
                        color: '#4F63BE',
                        mt:1,
                        mr: 1,
                        p: 1,
                        '&:hover': {
                          backgroundColor: '#D9D9D9',
                        },
                      }}
                      
                    >
                      <PersonOffOutlinedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </CustomTooltip>
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CustomersList;
