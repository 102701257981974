// DashboardLayout.js
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';  // Your Sidebar component
import Topbar from '../components/Navbar';   // Your Navbar/Topbar component
import { Box } from '@mui/material';

const DashboardLayout = () => {
  const [isSidebarHidden, setSidebarHidden] = useState(false);
  const location = useLocation();

  // Define routes where the sidebar should be hidden (optional)
  const pagesWithHiddenSidebar = ['/login', '/signup', '/forgot-password', '/integrations', '/workflow-library','/workflow-builder','/exp-runmybot','/event-pro'];
  const isPageWithHiddenSidebar = pagesWithHiddenSidebar.includes(location.pathname);

  useEffect(() => {
    setSidebarHidden(isPageWithHiddenSidebar);
  }, [location.pathname]);
  const toggleSidebar = () => {
    setSidebarHidden(!isSidebarHidden);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar />
      <Sidebar isHidden={isSidebarHidden} toggleSidebar={toggleSidebar} sx={{ marginTop:'73px' }}  />
      
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isSidebarHidden ? 0 : '273px', // Dynamically adjust for sidebar visibility
          transition: 'margin-left 0.3s ease',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;

// import React, { useEffect, useState } from 'react';
// import { Outlet, useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import Sidebar from '../components/Sidebar';
// import Topbar from '../components/Navbar';
// import { Box } from '@mui/material';
// // import { collapseSidebar, expandSidebar } from '../actions/sidebarActions';

// const DashboardLayout = ({ children }) => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
//   const dispatch = useDispatch();
//   // Toggle sidebar visibility
//   const toggleSidebar = () => {
//     setSidebarHidden(!isSidebarHidden);
//   };
//   const location = useLocation();
//   // Define routes where the sidebar should not be shown (e.g., login)
//   const noSidebarRoutes = ['/login'];
//   // Define pages where the sidebar should be hidden
//   const pagesWithHiddenSidebar = ['/login','/signup', '/integrations', '/workflow-library','/workflow-builder','/exp-runmybot','/event-pro'];
//   // Check if the current page should hide the sidebar
//   let cleanedPath = location.pathname.replace(/\/\d+$/, '');  
//   const isPageWithHiddenSidebar = pagesWithHiddenSidebar.includes(cleanedPath);

//   // State to control the sidebar visibility
//   const [isSidebarHidden, setSidebarHidden] = useState(false);
//   useEffect(() => {
//     // Update the sidebar visibility based on the current page
//     setSidebarHidden(isPageWithHiddenSidebar);
//   }, [location.pathname, isPageWithHiddenSidebar]);


//   return (
//     <Box sx={{ display: 'flex' }}>
   
//       <Sidebar isHidden={isSidebarHidden} toggleSidebar={toggleSidebar} />
//       <Topbar toggleSidebar={toggleSidebar} />
//       <Box
//         className="content"
//         sx={{
//           width: '100%',
//           marginLeft: isSidebarHidden ? '0' : '250px', // Apply dynamic margin based on sidebar visibility
//           transition: 'margin-left 0.3s ease', // Optional: Add smooth transition effect
//         }}
//       >
//         <Outlet />
//       </Box>
      
//       </Box>
    
//   );
// };

// export default DashboardLayout;
