import React, { memo, useState } from "react";
import { GripVertical, MoveRight, Pencil } from "lucide-react";
import CustomDropdown from "../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import DynamicPopup from "../../../../../../components/reusableComponents/DynamicPopup/DynamicPopup";
import { dataTypeOptions, mappingTypeOptions } from "./MappingFieldConstants";
import ActionButtons from "./ActionButtons/ActionButtons";
import {
  MAPPING_TYPES,
  createHandleChange,
  createHandleSave,
  createPopupContent,
  getActionButtonStates,
  transformSchemaToDropdownFormat
} from "./MappingFieldRowHelpers";
import styles from "./MappingFieldRow.module.css";

const dropdownStyle = {
  boxSizing: 'border-box',
  borderRadius: '8px',
  height: '36px'
};

const MappingFieldRow = memo(({ 
  mappingField, 
  onFieldMappingChange, 
  onDelete, 
  sourceNode,
  targetNode,
  mappingType 
}) => {
  const [activePopup, setActivePopup] = useState(null);
  const [isSourceCustomInput, setIsSourceCustomInput] = useState(false);
  const [isTargetCustomInput, setIsTargetCustomInput] = useState(false);
  const [customSourceField, setCustomSourceField] = useState('');
  const [customTargetField, setCustomTargetField] = useState('');

  const handleChange = createHandleChange(mappingField, onFieldMappingChange, setActivePopup);
  const handleSave = createHandleSave(mappingField, onFieldMappingChange, setActivePopup);
  const handleValidate = () => setActivePopup('validation');

  const sourceFields = transformSchemaToDropdownFormat(
    mappingType === 'input' ? sourceNode.payload.output : targetNode.payload.input
  );
  const targetFields = transformSchemaToDropdownFormat(
    mappingType === 'input' ? targetNode.payload.input : sourceNode.payload.output
  );

  const popupContent = createPopupContent(mappingField);
  const actionButtonStates = getActionButtonStates(mappingField);

  const handleCustomSourceInputChange = (e) => {
    const value = e.target.value;
    setCustomSourceField(value);
    handleChange('sourceField')(value);
  };

  const handleCustomTargetInputChange = (e) => {
    const value = e.target.value;
    setCustomTargetField(value);
    handleChange('targetField')(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.fieldRow}>
        <div className={styles.dragHandle}>
          <GripVertical className={styles.dragIcon} />
        </div>

        <div className={styles.fieldSelectors}>
          {/* Source Section */}
          <div className={styles.fieldPair}>
            <div className={`${styles.fieldContainer} ${styles.field}`}>
              <label className={styles.fieldLabel}>
                {mappingType === 'input' ? 'Source Field' : 'Target Field'}
              </label>
              <div className={styles.fieldInputContainer}>
                {isSourceCustomInput ? (
                  <input
                    type="text"
                    value={customSourceField}
                    onChange={handleCustomSourceInputChange}
                    className={styles.customInput}
                    placeholder="Enter field name"
                  />
                ) : (
                  <CustomDropdown
                    items={mappingType === 'input' ? sourceFields : targetFields}
                    value={mappingField.sourceField}
                    onChange={handleChange('sourceField')}
                    style={dropdownStyle}
                    placeholder="Select Field"
                    choosenShouldShow="value"
                  />
                )}
                <button
                  onClick={() => {
                    setIsSourceCustomInput(!isSourceCustomInput);
                    if (!isSourceCustomInput) {
                      setCustomSourceField(mappingField.sourceField || '');
                    }
                  }}
                  className={`${styles.typeToggle} ${isSourceCustomInput ? styles.typeToggleActive : styles.typeToggleInactive}`}
                  title={isSourceCustomInput ? "Switch to dropdown" : "Switch to custom input"}
                >
                  <Pencil 
                    size={20} 
                    className={isSourceCustomInput ? styles.typeToggleActive : styles.typeToggleInactive}
                  />
                </button>
              </div>
            </div>
            <div className={`${styles.fieldContainer} ${styles.type}`}>
              <label className={styles.fieldLabel}>Source Pencil</label>
              <CustomDropdown
                items={dataTypeOptions}
                value={mappingField.sourceDataType}
                onChange={handleChange('sourceDataType')}
                style={dropdownStyle}
                placeholder="Data Pencil"
                choosenShouldShow="label"
              />
            </div>
          </div>

          {/* Arrow */}
          <span className={styles.mappingArrow}>
            <MoveRight />
          </span>

          {/* Target Section */}
          <div className={styles.fieldPair}>
            <div className={`${styles.fieldContainer} ${styles.field}`}>
              <label className={styles.fieldLabel}>
                {mappingType === 'input' ? 'Target Field' : 'Source Field'}
              </label>
              <div className={styles.fieldInputContainer}>
                {isTargetCustomInput ? (
                  <input
                    type="text"
                    value={customTargetField}
                    onChange={handleCustomTargetInputChange}
                    className={styles.customInput}
                    placeholder="Enter field name"
                  />
                ) : (
                  <CustomDropdown
                    items={mappingType === 'input' ? targetFields : sourceFields}
                    value={mappingField.targetField}
                    onChange={handleChange('targetField')}
                    style={dropdownStyle}
                    placeholder="Select Field"
                    choosenShouldShow="value"
                  />
                )}
                <button
                  onClick={() => {
                    setIsTargetCustomInput(!isTargetCustomInput);
                    if (!isTargetCustomInput) {
                      setCustomTargetField(mappingField.targetField || '');
                    }
                  }}
                  className={`${styles.typeToggle} ${isTargetCustomInput ? styles.typeToggleActive : styles.typeToggleInactive}`}
                  title={isTargetCustomInput ? "Switch to dropdown" : "Switch to custom input"}
                >
                  <Pencil 
                    size={20} 
                    className={isTargetCustomInput ? styles.typeToggleActive : styles.typeToggleInactive}
                  />
                </button>
              </div>
            </div>
            <div className={`${styles.fieldContainer} ${styles.type}`}>
              <label className={styles.fieldLabel}>Target Pencil</label>
              <CustomDropdown
                items={dataTypeOptions}
                value={mappingField.targetDataType}
                onChange={handleChange('targetDataType')}
                style={dropdownStyle}
                placeholder="Data Pencil"
                choosenShouldShow="label"
              />
            </div>
          </div>

          {/* Actions */}
          <div className={styles.actions}>
            <div className={styles.fieldContainer}>
              <label className={styles.fieldLabel}>Mapping Pencil</label>
              <CustomDropdown
                items={mappingTypeOptions}
                value={mappingField.mappingType}
                onChange={handleChange('mappingType')}
                style={dropdownStyle}
                placeholder="Select Pencil"
              />
            </div>
            <ActionButtons 
              onDelete={onDelete}
              onTransform={() => setActivePopup(MAPPING_TYPES.TRANSFORM)}
              onLookup={() => setActivePopup(MAPPING_TYPES.LOOKUP)}
              onExpression={() => setActivePopup(MAPPING_TYPES.EXPRESSION)}
              onStatic={() => setActivePopup(MAPPING_TYPES.STATIC)}
              onValidate={handleValidate}
              {...actionButtonStates}
            />
          </div>
        </div>
      </div>

      {activePopup && (() => {
        const { title, Component, props } = popupContent[activePopup];
        return (
          <DynamicPopup
            title={title}
            onClose={() => setActivePopup(null)}
            style={{
              minHeight: "25rem",
              minWidth: "65%",
              background: "#fff",
            }}
            expanded={true}
          >
            <Component
              {...props}
              onSave={handleSave(activePopup)}
              onClose={() => setActivePopup(null)}
            />
          </DynamicPopup>
        );
      })()}
    </div>
  );
});

MappingFieldRow.displayName = 'MappingFieldRow';
export default MappingFieldRow;