import React from 'react';
import { getValidationAttributes } from '../../../../../utils/validationUtils';
import styles from './AutocompleteField.module.css';

const AutocompleteField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>
                {field.label}
            </label>
            <input
                type="text"
                id={field.id}
                placeholder={field.placeholder}
                value={value}
                required={field.required}
                onChange={(e) => onChange(e.target.value)}
                className={styles.autocompleteInput}
                list={`${field.id}-options`}
                style={style.input}
                {...validationAttributes}
            />
            <datalist id={`${field.id}-options`}>
                {field.options.map((option) => (
                    <option key={option.value} value={option.label} />
                ))}
            </datalist>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default AutocompleteField;
