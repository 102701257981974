import React, { useState } from "react";
import { Map, Share2 } from 'lucide-react';
import styles from "./MappingAndTransformationTabs.module.css";
import MappingForm from "../MappingForm/MappingForm";

const MappingAndTransformationTabs = ({
  currentNode,
  connectedSources,
  connectedTargets,
  onUpdateNode
}) => {
  const [activeTab, setActiveTab] = useState("input");

  const tabs = [
    { 
      id: 'input', 
      label: 'Input Mapping', 
      icon: Map,
      description: 'Map source node output to current node input'
    },
    { 
      id: 'output', 
      label: 'Output Mapping', 
      icon: Share2,
      description: 'Map current node output to target node input'
    }
  ];

  const handleMappingChange = (updatedMappings) => {
    onUpdateNode({
      ...currentNode,
      mappings: {
        ...currentNode.mappings,
        [activeTab]: updatedMappings
      }
    });
  };

  const getMappingConfig = () => {
    const mappingConfigs = {
      input: {
        sourceNodes: connectedSources,
        targetNode: currentNode
      },
      output: {
        sourceNode: currentNode,
        targetNodes: connectedTargets
      }
    };

    return mappingConfigs[activeTab];
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabList}>
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={`${styles.tabButton} ${activeTab === tab.id ? styles.activeTab : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            <div className={styles.tabContent}>
              <div className={styles.tabHeader}>
                <tab.icon className={styles.tabIcon} />
                <span className={styles.tabLabel}>{tab.label}</span>
              </div>
              <span className={styles.tabDescription}>{tab.description}</span>
            </div>
          </button>
        ))}
      </div>

      <div className={styles.mappingContainer}>
        <MappingForm
          mappingType={activeTab}
          currentMappings={currentNode.mappings?.[activeTab] || []}
          mappingConfig={getMappingConfig()}
          onMappingChange={handleMappingChange}
        />
      </div>
    </div>
  );
};

export default MappingAndTransformationTabs;