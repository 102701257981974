export const initializeState = (initialConfig = null) => ({
  steps: initialConfig?.steps || [],
  history: [initialConfig?.steps || []],
  currentIndex: 0,
  hasChanges: false,
  preview: null,
  validationAttempted: false,
  invalidFields: new Set()
});

export const createStepOperations = (state, setState, updateHistory, onSave) => ({
  add: () => {
    const emptyFieldIndexes = state.steps
      .map((step, index) => (!step.source?.trim() || !step.destination?.trim() ? index : -1))
      .filter(index => index !== -1);

    if (emptyFieldIndexes.length > 0) {
      setState(prev => ({
        ...prev,
        validationAttempted: true,
        invalidFields: new Set(emptyFieldIndexes)
      }));
      return;
    }

    const newSteps = [...state.steps, { source: '', destination: '' }];
    updateHistory(newSteps);
    
    setState(prev => ({
      ...prev,
      validationAttempted: false,
      invalidFields: new Set()
    }));
  },

  undo: () => {
    if (state.currentIndex > 0) {
      setState(prev => ({
        ...prev,
        steps: prev.history[prev.currentIndex - 1],
        currentIndex: prev.currentIndex - 1,
        hasChanges: true,
        validationAttempted: false,
        invalidFields: new Set()
      }));
    }
  },

  redo: () => {
    if (state.currentIndex < state.history.length - 1) {
      setState(prev => ({
        ...prev,
        steps: prev.history[prev.currentIndex + 1],
        currentIndex: prev.currentIndex + 1,
        hasChanges: true,
        validationAttempted: false,
        invalidFields: new Set()
      }));
    }
  },

  save: () => {
    onSave();
    setState(prev => ({
      ...prev,
      hasChanges: false,
      validationAttempted: false,
      invalidFields: new Set()
    }));
  },

  delete: (index) => {
    const newSteps = state.steps.filter((_, i) => i !== index);
    updateHistory(newSteps);
    
    setState(prev => ({
      ...prev,
      validationAttempted: false,
      invalidFields: new Set()
    }));
  },

  change: (index, field, value) => {
    const newSteps = state.steps.map((step, i) => 
      i === index ? { ...step, [field]: value } : step
    );
    updateHistory(newSteps);

    setState(prev => {
      const newInvalidFields = new Set(prev.invalidFields);
      const step = newSteps[index];
      
      if (prev.validationAttempted) {
        if (!step.source?.trim() || !step.destination?.trim()) {
          newInvalidFields.add(index);
        } else {
          newInvalidFields.delete(index);
        }
      }

      return {
        ...prev,
        invalidFields: newInvalidFields
      };
    });
  }
});

export const updateHistory = (setState, newSteps) => {
  setState(prev => {
    const newHistory = prev.history.slice(0, prev.currentIndex + 1);
    return {
      ...prev,
      steps: newSteps,
      history: [...newHistory, newSteps],
      currentIndex: newHistory.length,
      hasChanges: true
    };
  });
};