// Constants
export const defaultFieldState = {
  sourceField: '',
  targetField: '',
  sourceType: 'string',
  targetType: 'string',
  mappingType: 'standard'
};

export const initialState = {
  selectedMapping: 'new',
  fields: []
};

// Helper Functions
export const createNewField = (position) => ({
  ...defaultFieldState,
  id: Date.now(),
  position
});

export const updateFieldPositions = (fields) => 
  fields.map((field, index) => ({
    ...field,
    position: index
  }));

export const getSelectedMappingData = (value, existingMappings) => {
  if (value === 'new') {
    return {
      fields: [createNewField(0)],
      name: 'New Mapping'
    };
  }

  const selectedMapping = existingMappings.find(mapping => mapping.id === value);

  if (!selectedMapping) {
    return {
      fields: [createNewField(0)],
      name: 'New Mapping'
    };
  }

  return {
    fields: selectedMapping.fields?.map((field, index) => ({
      ...field,
      id: field.id || Date.now(),
      position: field.position || index
    })).sort((a, b) => a.position - b.position),
    name: selectedMapping.name || `Mapping ${selectedMapping.id}`
  };
};

export const createMappingData = (
  selectedMapping, 
  mappingName, 
  mappingFields,
  { sourceNode, targetNode, mappingType }
) => {
  const fieldsWithPositions = updateFieldPositions(mappingFields);
  
  return {
    ...(selectedMapping !== 'new' && { id: selectedMapping }),
    name: mappingName,
    source: {
      sourceNodeId: sourceNode.id,
      sourceNodeName: sourceNode.data?.properties?.label || sourceNode.id
    },
    target: {
      targetNodeId: targetNode.id,
      targetNodeName: targetNode.data?.properties?.label || targetNode.id
    },
    type: mappingType,
    fields: fieldsWithPositions
  };
};

export const getMappingName = (selectedMapping, existingMappings) => {
  if (selectedMapping === 'new') return 'New Mapping';
  const mapping = existingMappings.find(m => m.id === selectedMapping);
  return mapping?.name || `Mapping ${selectedMapping}`;
};

export const isValidMapping = (mappingFields) => 
  mappingFields.some(field => field.sourceField && field.targetField);

// Complex Operations Helpers
export const handleFieldOperation = (operation, setHasChanges) => (prevFields) => {
  const updatedFields = operation(prevFields);
  setHasChanges(true);
  return updatedFields;
};

export const addField = (prevFields) => [
  ...prevFields, 
  createNewField(prevFields.length)
];

export const deleteField = (id, prevFields) => 
  updateFieldPositions(prevFields.filter(field => field.id !== id));

export const updateField = (id, field, value, prevFields) => 
  prevFields.map(item => item.id === id ? { ...item, [field]: value } : item);

export const reorderFields = (newOrder) => updateFieldPositions(newOrder);