import React, { useState, useCallback, memo, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Settings } from 'lucide-react';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import StepRow from './StepRow';
import ControlsSection from '../sharedHelpers/ControlsSection';
import {
  createInitialState,
  updateTransformationState,
  handleStepChange,
  handleUndo,
  handleRedo,
  handleSave,
  handleDeleteStep,
  getFunctionParams,
  validateSingleStep,
  createNewStep
} from './transformationHelpers';
import styles from './TransformationContent.module.css';

const MemoizedStep = memo(({ 
  step, 
  index, 
  onStepChange, 
  onDelete, 
  params, 
  errors = {},
  saveError,
  showValidation = false,
  isIncomplete = false
}) => {
  if (!step) return null;

  return (
    <div className={`${styles.stepItem} ${isIncomplete ? styles.incomplete : ''}`}>
      <StepRow
        step={step}
        index={index}
        onStepChange={onStepChange}
        onDelete={onDelete}
        params={params}
        errors={errors}
        saveError={saveError}
        showValidation={showValidation}
        isIncomplete={isIncomplete}
      />
    </div>
  );
});

const TransformationContent = memo(({ 
  initialSteps = [], 
  onClose, 
  onSave,
  initialValue = '' 
}) => {
  const [state, setState] = useState(() => createInitialState(initialSteps));

  const handleStepChangeCallback = useCallback((stepId, field, value) => {
    if (!stepId || !field) return;

    setState(prev => {
      if (!prev?.steps) return prev;

      const newSteps = handleStepChange(stepId, field, value, prev.steps);
      return updateTransformationState(newSteps, prev, prev.validationAttempted);
    });
  }, []);

  const handleSaveClick = useCallback(() => {
    if (!state) return;

    setState(prev => ({
      ...prev,
      validationAttempted: true
    }));
    
    const result = handleSave(state, initialValue, onSave, onClose);
    
    if (!result?.success) {
      setState(prev => ({
        ...prev,
        errors: result?.errors || { save: result?.error || 'Unknown error occurred' }
      }));

      const firstErrorEl = document.querySelector(`.${styles.error}`);
      firstErrorEl?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
    }
  }, [state, initialValue, onSave, onClose]);

  const handleAddStepWithValidation = useCallback(() => {
    setState(prev => {
      if (!prev?.steps) return prev;

      const lastStep = prev.steps[prev.steps.length - 1];
      const isLastStepIncomplete = lastStep && !validateSingleStep(lastStep);

      if (isLastStepIncomplete) {
        return {
          ...prev,
          validationAttempted: true
        };
      }

      const newStep = createNewStep();
      if (!newStep) return prev;

      const newSteps = [...prev.steps, newStep];
      return {
        ...prev,
        steps: newSteps,
        history: [...(prev.history || []).slice(0, (prev.currentIndex || 0) + 1), newSteps],
        currentIndex: (prev.currentIndex || 0) + 1,
        hasChanges: true,
        validationAttempted: false
      };
    });
  }, []);

  const stepOperations = useMemo(() => ({
    undo: () => setState(prev => {
      if (!prev) return prev;
      return {
        ...handleUndo(prev),
        validationAttempted: false
      };
    }),
    redo: () => setState(prev => {
      if (!prev) return prev;
      return {
        ...handleRedo(prev),
        validationAttempted: false
      };
    }),
    save: handleSaveClick,
    add: handleAddStepWithValidation,
    delete: (stepId) => {
      if (!stepId) return;
      setState(prev => {
        if (!prev?.steps) return prev;

        const newSteps = handleDeleteStep(stepId, prev.steps);
        return {
          ...updateTransformationState(newSteps, prev, prev.validationAttempted),
          validationAttempted: false
        };
      });
    }
  }), [handleSaveClick, handleAddStepWithValidation]);

  const stepsList = useMemo(() => {
    if (!state?.steps?.length) return null;

    return (
      <AnimatePresence initial={false}>
        {state.steps.map((step, idx) => {
          if (!step?.id) return null;

          const params = step.function ? getFunctionParams(step.function) : undefined;
          const isIncomplete = !validateSingleStep(step);
          const stepErrors = state.errors?.[step.id] || {};

          return (
            <MemoizedStep
              key={step.id}
              step={step}
              index={idx}
              onStepChange={handleStepChangeCallback}
              onDelete={() => stepOperations.delete(step.id)}
              params={params}
              errors={stepErrors}
              saveError={state.errors?.save}
              showValidation={!!state.validationAttempted}
              isIncomplete={isIncomplete}
            />
          );
        })}
      </AnimatePresence>
    );
  }, [state?.steps, state?.errors, state?.validationAttempted, handleStepChangeCallback, stepOperations.delete]);

  const hasSteps = state?.steps?.length > 0;
  const canUndo = (state?.currentIndex || 0) > 0;
  const canRedo = state?.history && state.currentIndex < state.history.length - 1;

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.stepsSection}>
          <div className={styles.stepsList}>
            {!hasSteps ? (
              <div className={styles.emptyStateContainer}>
                <EmptyState
                  icon={Settings}
                  title="No transformation steps configured"
                  message="Click the Add button to create a transformation step"
                />
              </div>
            ) : (
              stepsList
            )}
          </div>
        </div>
        <ControlsSection
          onAdd={stepOperations.add}
          onUndo={stepOperations.undo}
          onRedo={stepOperations.redo}
          onSave={stepOperations.save}
          onClose={onClose}
          canUndo={canUndo}
          canRedo={canRedo}
          preview={state?.preview}
          isDisabled={!state?.hasChanges}
        />
      </div>
    </div>
  );
});

MemoizedStep.displayName = 'MemoizedStep';
TransformationContent.displayName = 'TransformationContent';

export default TransformationContent;