import React, { memo } from "react";
import styles from "./TableHeader.module.css";
import { Info } from "lucide-react"; // Assuming you're using lucide-react for icons

const TableHeader = memo(() => (
  <div className={styles.tableHeader}>
    <div className={styles.instructionContent}>
      <Info size={20} className={styles.infoIcon} />
      <div className={styles.instructions}>
        Create new or edit existing mappings with field validations and transformations. Drag to reorder.
      </div>
    </div>
  </div>
));

export default TableHeader;