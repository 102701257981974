import { delay, put, select, take } from 'redux-saga/effects';
import { REFRESH_TOKEN_REQUEST } from '../actions/authActions';

export function* watchTokenExpiration() {
    while (true) {
        const { accessToken, refreshToken } = yield select(state => state.auth);
        if (accessToken && refreshToken) {
            try {
                // Decode the JWT payload
                const jwtPayload = JSON.parse(atob(accessToken.split('.')[1]));
                const expTime = jwtPayload.exp * 1000; // Convert to milliseconds 
                const currentTime = Date.now();
                const timeLeft = expTime - currentTime;

                if (timeLeft <= 0) {
                    // Token already expired, trigger refresh immediately
                    yield put({ type: REFRESH_TOKEN_REQUEST, payload: { refreshToken } });
                } else {
                    // Wait until the token is about to expire
                    yield delay(timeLeft);
                    yield put({ type: REFRESH_TOKEN_REQUEST, payload: { refreshToken } });
                }
            } catch (error) {
                console.error("Error decoding accessToken:", error);
                // Handle the error gracefully, like logging out the user or showing an alert
            }
        } else {
            console.warn("Missing tokens. Waiting for login...");
        }

        // Wait for the next login success before continuing the loop
        yield take('LOGIN_SUCCESS');
    }
}
