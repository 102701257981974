// api.js or EmailVerificationApi.js
export const verifyEmailApi = async (code, token) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/verify-email/${code}/${token}/`;
  
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error('Email verification failed');
    }
  
    return response;
  };
  