import React, { memo } from 'react';
import { Plus, Undo2, Redo2, Check, X, Wand2 } from 'lucide-react';
import styles from './ControlsSection.module.css';

const ControlsSection = memo(({ 
  onAdd, 
  onUndo, 
  onRedo, 
  onSave, 
  onClose, 
  canUndo, 
  canRedo, 
  preview, 
  isDisabled 
}) => (
  <div className={styles.container}>
    <div className={styles.controlsGroup}>
    {onAdd && <button type="button" onClick={onAdd} className={styles.addButton}>
        <Plus size={16} /> Add
      </button>}
      <div className={styles.operations}>
        <button type="button" onClick={onUndo} disabled={!canUndo} className={styles.operationButton}>
          <Undo2 size={16} /> Undo
        </button>
        <button type="button" onClick={onRedo} disabled={!canRedo} className={styles.operationButton}>
          <Redo2 size={16} /> Redo
        </button>
      </div>
    </div>

    {preview && (
      <div className={styles.previewSection}>
        <div className={styles.preview}>
          <div className={styles.previewHeader}>
            <Wand2 size={16} /> Preview
          </div>
          <div className={styles.previewContent}>{preview}</div>
        </div>
      </div>
    )}

    <div className={styles.footer}>
      <button type="button" onClick={onClose} className={styles.cancelButton}>
        <X size={16} /> Cancel
      </button>
      <button
        type="button"
        onClick={onSave}
        disabled={isDisabled}
        className={styles.saveButton}
      >
        <Check size={16} /> Apply Changes
      </button>
    </div>
  </div>
));

export default ControlsSection;