import React, { useState }  from 'react';
import { Drawer, List, ListItem, ListItemText, CssBaseline, AppBar, Toolbar, Box, Typography, ListItemIcon, Avatar, IconButton, MenuItem, Menu, Badge, Grid, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Activities from '../components/Activities';
import MyWorkflows from '../components/MyWorkflows';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import DashboardOverview from '../components/DashboardOverview';
import IntegrationsLibraryPage from './integrations/pages/IntegrationsLibraryPage/IntegrationsLibraryPage';
import DashboardLayout from '../layouts/DashboardLayout';


const drawerWidth = 10;

const Dashboard = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState('overview'); 
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const renderContent = () => {
    console.log('activeSection: ',activeSection)
    switch (activeSection) {
      case 'overview':
        return <DashboardOverview />; // Load overview section
      case 'activities':
        return <Activities />
      case 'integrations':
        return <IntegrationsLibraryPage />; // Load integrations section
      default:
        return <DashboardOverview />;
    }
  };
  return(
    <Box sx={{ flexGrow: 1, p: 5 }}>
      {/* Header and other UI components */}

      <Grid container spacing={1} sx={{ mt: 5, border: 'none', p: 0 }}>
        <Grid item xs={12}>
          <MyWorkflows />
          {/* {renderComponent()} */}
        </Grid>
      </Grid>
    </Box>
  )
  
};

export default Dashboard;
