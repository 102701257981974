import React from 'react';
import { Grid, Box } from '@mui/material';
import MyWorkflows from './MyWorkflows';
import Activities from './Activities';
import { useLocation } from 'react-router-dom';

const DashboardOverview = () => {
  const location = useLocation();
  const { view } = location.state || {};  // Default to empty if no state

  const renderComponent = () => {
    switch (view) {
      case 'Activities':
        return <Activities />;
      case 'Workflows':
      default:
        return <MyWorkflows />;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      {/* Header and other UI components */}

      <Grid container spacing={1} sx={{ mt: 5, border: 'none', p: 0 }}>
        <Grid item xs={12}>
          <Activities />
          {/* {renderComponent()} */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardOverview;
