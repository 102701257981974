// utils/validationUtils.js
export const getValidationAttributes = (field) => {
    if (!field.validation) return {};

    return Object.entries(field.validation).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});
};

export const validateField = (field, value) => {
    const { validation = {}, label } = field;
    const errorMessages = [];

    if (validation.required && (!value || (Array.isArray(value) && value.length === 0))) {
        errorMessages.push(`${label} is required`);
    }

    Object.entries(validation).forEach(([rule, ruleValue]) => {
        if (rule === 'minLength' && value?.length < ruleValue) {
            errorMessages.push(`${label} should be at least ${ruleValue} characters`);
        }
        if (rule === 'maxLength' && value?.length > ruleValue) {
            errorMessages.push(`${label} should be less than ${ruleValue} characters`);
        }
        if (rule === 'pattern' && value && !new RegExp(ruleValue).test(value)) {
            errorMessages.push(`${label} is not in the correct format`);
        }
        if (rule === 'min' && value && Number(value) < ruleValue) {
            errorMessages.push(`${label} should be at least ${ruleValue}`);
        }
        if (rule === 'max' && value && Number(value) > ruleValue) {
            errorMessages.push(`${label} should be at most ${ruleValue}`);
        }
    });

    return errorMessages.join(', ');
};