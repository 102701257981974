export const VALIDATION_TYPES = {
    required: {
      label: 'Required',
      description: 'Field must have a value',
      params: [
        {
          name: 'value',
          type: 'boolean',
          placeholder: 'Select true or false'
        }
      ]
    },
    minLength: {
      label: 'Minimum Length',
      description: 'Field must be at least the specified length',
      params: [
        {
          name: 'length',
          type: 'number',
          placeholder: 'Enter minimum length',
          min: 1
        }
      ]
    },
    maxLength: {
      label: 'Maximum Length',
      description: 'Field must not exceed the specified length',
      params: [
        {
          name: 'length',
          type: 'number',
          placeholder: 'Enter maximum length',
          min: 1
        }
      ]
    },
    range: {
      label: 'Number Range',
      description: 'Value must be within the specified range',
      params: [
        {
          name: 'min',
          type: 'number',
          placeholder: 'Enter minimum value'
        },
        {
          name: 'max',
          type: 'number',
          placeholder: 'Enter maximum value'
        }
      ]
    },
    pattern: {
      label: 'Pattern Match',
      description: 'Value must match the specified pattern',
      params: [
        {
          name: 'pattern',
          type: 'text',
          placeholder: 'Enter pattern (e.g., [A-Za-z]+)'
        }
      ]
    }
  };
  
  export const validationTypeOptions = Object.entries(VALIDATION_TYPES).map(([value, config]) => ({
    value,
    label: config.label
  }));
  
  export const initializeState = (initialConfig = null) => ({
    validations: initialConfig || [],
    history: [[]],
    currentIndex: 0,
    hasChanges: true,
    errors: {},
    validationAttempted: false,
    invalidFields: new Set()
  });
  
  const validateParam = (param, value) => {
    switch (param.type) {
      case 'boolean':
        return value !== undefined && value !== '';
      case 'number':
        const numValue = parseFloat(value);
        return !isNaN(numValue) && 
               (!param.min || numValue >= param.min) && 
               (!param.max || numValue <= param.max);
      case 'text':
        return value !== undefined && value.trim() !== '';
      default:
        return true;
    }
  };
  
  export const validateValidation = (validation) => {
    const errors = {};
    const validationType = VALIDATION_TYPES[validation.type];
    
    validationType.params.forEach(param => {
      const value = validation.params[param.name];
      if (!validateParam(param, value)) {
        errors[param.name] = `Invalid ${param.name}`;
      }
    });
  
    if (validation.type === 'range' && validation.params.min && validation.params.max) {
      if (parseFloat(validation.params.min) >= parseFloat(validation.params.max)) {
        errors._form = 'Maximum value must be greater than minimum value';
      }
    }
  
    return Object.keys(errors).length > 0 ? errors : null;
  };
  
  export const createValidationOperations = (state, setState, updateHistory, onSave) => ({
    add: () => {
      const incompleteValidations = state.validations.filter(v => !isValidationComplete(v));
      
      if (incompleteValidations.length > 0) {
        setState(prev => {
          const newInvalidFields = new Set();
          incompleteValidations.forEach(v => newInvalidFields.add(v.id));
          return {
            ...prev,
            validationAttempted: true,
            invalidFields: newInvalidFields
          };
        });
        return;
      }
  
      const newValidation = {
        id: Date.now(),
        type: Object.keys(VALIDATION_TYPES)[0],
        params: {}
      };
      const newValidations = [...state.validations, newValidation];
      updateHistory(newValidations);
      setState(prev => ({
        ...prev,
        validationAttempted: false,
        invalidFields: new Set()
      }));
    },
  
    undo: () => {
      if (state.currentIndex > 0) {
        setState(prev => ({
          ...prev,
          validations: prev.history[prev.currentIndex - 1],
          currentIndex: prev.currentIndex - 1,
          hasChanges: true,
          errors: {},
          validationAttempted: false,
          invalidFields: new Set()
        }));
      }
    },
  
    redo: () => {
      if (state.currentIndex < state.history.length - 1) {
        setState(prev => ({
          ...prev,
          validations: prev.history[prev.currentIndex + 1],
          currentIndex: prev.currentIndex + 1,
          hasChanges: true,
          errors: {},
          validationAttempted: false,
          invalidFields: new Set()
        }));
      }
    },
  
    save: () => {
      const errors = {};
      let hasErrors = false;
  
      state.validations.forEach(validation => {
        if (!isValidationComplete(validation)) {
          errors[validation.id] = { _form: 'Please complete all validation fields' };
          hasErrors = true;
        } else {
          const validationErrors = validateValidation(validation);
          if (validationErrors) {
            errors[validation.id] = validationErrors;
            hasErrors = true;
          }
        }
      });
  
      if (hasErrors) {
        setState(prev => ({
          ...prev,
          errors,
          validationAttempted: true,
          invalidFields: new Set(Object.keys(errors))
        }));
        return false;
      }
  
      onSave(state.validations);
      setState(prev => ({
        ...prev,
        hasChanges: false,
        errors: {},
        validationAttempted: false,
        invalidFields: new Set()
      }));
      return true;
    },
  
    delete: (id) => {
      const newValidations = state.validations.filter(v => v.id !== id);
      setState(prev => {
        const newErrors = { ...prev.errors };
        delete newErrors[id];
        const newInvalidFields = new Set(prev.invalidFields);
        newInvalidFields.delete(id);
        return {
          ...prev,
          errors: newErrors,
          invalidFields: newInvalidFields
        };
      });
      updateHistory(newValidations);
    },
  
    change: (id, field, value) => {
      const newValidations = state.validations.map(validation => {
        if (validation.id === id) {
          if (field === 'type') {
            return { ...validation, type: value, params: {} };
          }
          const newParams = { ...validation.params, [field]: value };
          return { ...validation, params: newParams };
        }
        return validation;
      });
  
      setState(prev => {
        const newInvalidFields = new Set(prev.invalidFields);
        if (value && value.trim && value.trim() !== '') {
          newInvalidFields.delete(id);
        }
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [id]: {}
          },
          invalidFields: newInvalidFields
        };
      });
  
      updateHistory(newValidations);
    }
  });
  
  export const updateHistory = (setState, newValidations) => {
    setState(prev => {
      const newHistory = prev.history.slice(0, prev.currentIndex + 1);
      return {
        ...prev,
        validations: newValidations,
        history: [...newHistory, newValidations],
        currentIndex: newHistory.length,
        hasChanges: true
      };
    });
  };

  const isValidationComplete = (validation) => {
    const validationType = VALIDATION_TYPES[validation.type];
    return validationType.params.every(param => {
      const value = validation.params[param.name];
      return value !== undefined && value !== '' && validateParam(param, value);
    });
  };