import React, { useState, useCallback, useEffect } from "react";
import { AlertCircle } from "lucide-react";
import MappingAndTransformationTabs from "./MappingAndTransformationTabs/MappingAndTransformationTabs";
import mockMappings from "./mockMappings.json";
import styles from "./MappingAndTransformationSection.module.css";

const MappingAndTransformationSection = ({ node, nodes, edges, onUpdateNode }) => {
  // Initialize currentNode with node prop, payload and mappings
  const [currentNode, setCurrentNode] = useState({
    ...node,
    payload: mockMappings?.Payload,
    mappings: mockMappings?.predefinedMappings || { input: [], output: [] }
  });

  // Update currentNode when node prop changes
  useEffect(() => {
    if (node) {
      setCurrentNode({
        ...node,
        payload: mockMappings?.Payload,
        mappings: mockMappings?.predefinedMappings || { input: [], output: [] }
      });
    }
  }, [node]);

  const getConnectedNodes = useCallback(() => {
    const connectedSources = [];
    const connectedTargets = [];

    if (!edges?.length || !nodes?.length) {
      console.error("Edges or nodes are not defined or empty.");
      return { connectedSources, connectedTargets };
    }

    edges.forEach((edge) => {
      // For input mappings (current node is target)
      if (edge.target === node.id) {
        const sourceNode = nodes.find((n) => n.id === edge.source);
        if (sourceNode) {
          connectedSources.push({
            ...sourceNode,
            payload: mockMappings?.Payload,
            mappings: mockMappings?.predefinedMappings || { input: [], output: [] }
          });
        }
      }
      
      // For output mappings (current node is source)
      if (edge.source === node.id) {
        const targetNode = nodes.find((n) => n.id === edge.target);
        if (targetNode) {
          connectedTargets.push({
            ...targetNode,
            payload: mockMappings?.Payload,
            mappings: mockMappings?.predefinedMappings || { input: [], output: [] }
          });
        }
      }
    });

    return { connectedSources, connectedTargets };
  }, [edges, nodes, node?.id]);

  const { connectedSources, connectedTargets } = getConnectedNodes();

  if (!node) {
    return null;
  }

  return (
    <div className={styles.container}>
      {(!connectedSources.length || !connectedTargets.length) ? (
        <div className={styles.warning}>
          <AlertCircle className={styles.warningIcon} />
          <span>Configure node connections to begin mapping</span>
        </div>
      ) : (
        <MappingAndTransformationTabs
          currentNode={currentNode}
          connectedSources={connectedSources}
          connectedTargets={connectedTargets}
          onUpdateNode={onUpdateNode}
        />
      )}
    </div>
  );
};

export default MappingAndTransformationSection;