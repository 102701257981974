import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as DropdownIconOpen } from '../../../assetts/images/dropdownIconOpen.svg';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import TruncatedText from './TruncatedText';
import DropdownItem from './DropdownItem';
import { searchInItems } from './utils';
import styles from './CustomDropdown.module.css';

function CustomDropdown({ 
  label, 
  items = [], 
  value, 
  onChange, 
  disabled = false, 
  style = {}, 
  multiSelect = false,
  choosenShouldShow = 'label'
}) {

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dropdownRef = useRef(null);

  const filteredItems = searchText ? searchInItems(items, searchText) : items;

  const getItemLabel = (itemValue) => {
    const findLabel = (items) => {
      for (let item of items) {
        if (item.value === itemValue) return item.label;
        if (item.children) {
          const label = findLabel(item.children);
          if (label) return label;
        }
      }
      return null;
    };
    return findLabel(items);
  };

  const selectedValue = multiSelect 
    ? (value || []).map(v => choosenShouldShow === 'value' ? v : getItemLabel(v)).join(', ') || 'Select'
    : value ? (choosenShouldShow === 'value' ? value : getItemLabel(value)) : 'Select';

  const handleSelect = (selectedValue) => {
    if (disabled) return;
    if (multiSelect) {
      const newValue = Array.isArray(value) ? value : [];
      onChange(newValue.includes(selectedValue) 
        ? newValue.filter(v => v !== selectedValue)
        : [...newValue, selectedValue]);
    } else {
      onChange(selectedValue);
      setIsOpen(false);
      setSearchText('');
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setIsOpen(false);
        setSearchText('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles.container} style={style} ref={dropdownRef}>
      {label && <label className={`${styles.label} ${disabled ? styles.disabled : ''}`}>{label}</label>}
      <div className={styles.dropdown}>
        <div
          className={`${styles.header} ${isOpen ? styles.open : ''} ${disabled ? styles.disabled : ''}`}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <div className={styles.headerContent}>
            <TruncatedText 
              text={selectedValue}
              className={styles.selectedText}
            />
          </div>
          {isOpen ? (
            <DropdownIconOpen className={styles.icon} />
          ) : (
            <DropdownIconClosed className={styles.icon} />
          )}
        </div>
        {isOpen && (
          <div className={styles.list}>
            <div className={styles.search}>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <DropdownItem
                  key={item.value}
                  item={item}
                  onSelect={handleSelect}
                  isSelected={multiSelect ? (value || []).includes(item.value) : value === item.value}
                  multiSelect={multiSelect}
                  value={value}
                />
              ))
            ) : (
              <div className={styles.noResults}>No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomDropdown;