import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './MyWorkflows.css';
import theme from '../theme';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import percent_image from '../assetts/images/percent_image.svg';
import StatusCard from './StatusCard';
import { getDashboardTiles , getDashboardDataList } from '../api/dashboardApi';
import SearchComponent from './common/SearchBox';
import Preloader from './common/Preloader';
import CustomTooltip from './reusableComponents/CustomTooltip/CustomTooltip';
import { isTokenValid, refreshAccessToken } from '../utils/tokenUtils';
import { reAuthenticationRequest } from '../redux/actions/authActions';

const MyWorkflows = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [tileData, setTileData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accessToken = useSelector ((state) => state.auth.accessToken);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const getTilesData = async () => {
      try {
        if (!isTokenValid(accessToken)) {
          accessToken = refreshAccessToken();
          dispatch(reAuthenticationRequest(accessToken));
        }
        const data = await getDashboardTiles(accessToken); // Call the API to fetch templates
        setTileData(data.workflow_count); // Set the 'results' array from the response
        console.log('data.workflow_count ',data.workflow_count)
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };

    getTilesData(); // Fetch templates when the component mounts
  }, [accessToken]); // Access token as dependency to ensure the correct token is used
  useEffect(() => {
    const getRowData = async () => {
      try {
        if (!isTokenValid(accessToken)) {
          accessToken = refreshAccessToken();
          if(accessToken) dispatch(reAuthenticationRequest(accessToken));
        }
        const data = await getDashboardDataList(accessToken); // Call the API to fetch templates
        setRowData(data.results); // Set the 'results' array from the response
        console.log('row data.  ',data.results)
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };

    getRowData(); // Fetch templates when the component mounts
  }, [accessToken]); // Access token as dependency to ensure the correct token is used

    // const rowData = [
    // { name: 'QuickBooks to SFTP Vendor Data Posting', status: 'Active', lastRun: '16:25   Jul 12, 2024', totalRuns: [1000, 0, 0, 1], actions: '...' },
    // { name: 'POS to NetSuite JE Post', status: 'Active', lastRun: '06:30   Jul 22, 2024', totalRuns: [9, 11, 0, 12], actions: '...' },
    // { name: 'Sharepoint to Azure File Transformation', status: 'Active', lastRun: '14:00   Jul 29, 2024', totalRuns: [0, 0, 0, 1], actions: '...' },
    // { name: 'QuickBooks to SFTP Vendor Data Posting', status: 'Active', lastRun: '16:25   Jul 12, 2024', totalRuns: [5600, 15, 35, 15], actions: '...' },
    // { name: 'POS to NetSuite JE Post', status: 'Active', lastRun: '06:30   Jul 22, 2024', totalRuns: [10, 50, 20, 100], actions: '...' },
    // { name: 'Sharepoint to Azure File Transformation', status: 'Inactive', lastRun: '14:00   Jul 29, 2024', totalRuns: [1, 5, 0, 5], actions: '...' },
    // { name: 'QuickBooks to SFTP Vendor Data Posting', status: 'Inactive', lastRun: '16:25   Jul 12, 2024', totalRuns: [0, 1, 2, 2], actions: '...' },
    
    // Add more rowData as needed
  // ];
  // Initial statusData state (before API result)
    const [statusData, setStatusData] = useState([
      { title: 'Success', count: 0, percentage: '50%', percent_image: percent_image },
      { title: 'Pending', count: 0, percentage: '62%', percent_image: percent_image },
      { title: 'Warning', count: 0, percentage: '5%', percent_image: percent_image },
      { title: 'Error', count: 0, percentage: '6%', percent_image: percent_image },
    ]);
    useEffect(() => {
      if (tileData) {
        // Update statusData with the result from tileData API
        console.log('tileData.pending ',tileData.success)
        setStatusData((prevStatusData) =>
          prevStatusData.map((status) => {
            switch (status.title) {
              case 'Success':
                return { ...status, count: tileData.success };
              case 'Pending':
                return { ...status, count: tileData.pending };
              case 'Warning':
                return { ...status, count: tileData.warning };
              case 'Error':
                return { ...status, count: tileData.failed };
              default:
                return status;
            }
          })
        );
      }
    }, [tileData]); // Dependency array ensures this effect runs when tileData changes
    const statuses = ['Success', 'In Progress', 'Warning', 'Error'];
    function getStatusColor(status, id = null) {
      // If id is provided, handle it specifically
      if (id !== null) {
        switch (id) {
          case 0:
            return theme.palette.success.main;
          case 1:
            return theme.palette.inprogress.main;
          case 2:
            return theme.palette.warning.main;
          case 3:
            return theme.palette.error.main;
          default:
            return theme.palette.grey[500];
        }
      }
    
        // Handle based on status if no id is provided
        switch (status) {
          case 'Success':
            return theme.palette.success.main;
          case 'In Progress':
            return theme.palette.inprogress.main;
          case 'Warning':
            return theme.palette.warning.main;
          case 'Error':
            return theme.palette.error.main;
          default:
            return theme.palette.grey[500];
        }
      }
      function formatRunNumber(run) {
        if (run >= 1000) {
          return (run / 1000).toFixed(0) + 'k'; // Convert to "k" format and keep one decimal point
        }
        return run.toString(); // Return as is for numbers less than 1000
      }
      const handleClickIntegration=()=>{
        console.log("handleClickIntegration")
        navigate('/integrations'); // Navigate to integrations page
      }
      const handleClickWorkflowLibrary=()=>{
        navigate('/workflow-library');
      }
      if (loading) {
        return <Preloader />; // Show preloader while loading
      }
  return (
    <>
      <Box>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {statusData.map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatusCard
              title={data.title}
              count={data.count}
              percentage={data.percentage}
              percent_image={data.percent_image}
            />
          </Grid>
        ))}
      </Grid>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          {/* Buttons */}
          <Grid item>
            <Box className="buttonGroup" display="flex" marginTop={5}>
              <Button
                variant="contained"
                sx={{
                  width: '130px',
                  height: '32px',
                  borderRadius: '24px',
                  backgroundColor: '#4F63BE',
                  boxShadow:'none',
                  textTransform: 'none',
                  '& .MuiButton-startIcon': {
                    borderRadius: '50%',
                    padding: '0px',
                  },
                }}
                startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%', marginLeft:'5px' }} />}
                onClick={handleClickIntegration} // Open drawer on click
              >
                Integrations
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: '140px',
                  height: '32px',
                  borderRadius: '24px',
                  backgroundColor: '#7398C7',
                  boxShadow:'none',
                  textTransform: 'none',
                  marginLeft: '8px', // Space between buttons
                  '&:hover': {
                    backgroundColor: '#7398C7',
                  },
                  '& .MuiButton-startIcon': {
                    borderRadius: '50%',
                    padding: '0px',
                  },
                }}
                startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%', fontSize: 20 ,marginLeft:'5px' }} />}
                onClick={handleClickWorkflowLibrary}
              >
                Workflows
              </Button>
            </Box>
          </Grid>


          {/* Right Group: Status Dots */}
          <Grid item>
            <Box display="flex" justifyContent="flex-end">
              {statuses.map((status, index) => (
                <Box key={index} display="flex" alignItems="center" sx={{ marginLeft: 5, marginTop:5}}>
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: '32px',
                      backgroundColor: getStatusColor(status),
                      marginRight: 1,
                    }}
                  />
                  <Typography variant="caption">{status}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{  mt: 5, border: '1.8px solid #ECEEF6', borderRadius: '25px', p: 2 }}>
       
        {/* Date filter and Search box can go here */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

        {/* Left side: Heading */}

        <Typography variant="h4" sx={{ fontWeight: 600, ml:2 }}> {/* Adjusted Typography variant as needed */}

          My Workflows

        </Typography>


      {/* Right side: Filter and Search Box */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      {/* Filter Box */}
      <Box
            sx={{
              minWidth: 115,
              minHeight: 32,
              backgroundColor: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'left',
              padding: '10px',
              fontWeight: 600,
              borderRadius: '24px',
              boxSizing: 'border-box',
              color: '#4F63BE',
              marginRight: 2, // Adds space between Filter and Search Box
            }}
          >
            <CalendarMonthOutlinedIcon sx={{ fontSize: 18, marginRight: '8px' }} />
            Filter
          </Box>


      <SearchComponent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </Box>
  </Box>
        <Box sx={{ mt: 3 }}>
          <TableContainer  component={Paper} sx={{ boxShadow: 'none', border: 'none'}}>
            <Table sx={{ borderCollapse: 'separate', borderSpacing: 0, border:'none' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...theme.typography.tableCellHeader }}>Name</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader }}>Status</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader }}>Last Run</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader }}>Total Runs</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData && rowData.length > 0 ? (
                  rowData.map((row, index) => (
                    <TableRow key={index} sx={{ border: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none', ...theme.typography.tableCellData, 
                    '&:hover': {
                      backgroundColor: '#f0f0f0'},}} // Optional: Change background color on hover }}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/process-flow/${row.id}`)}
                      >
                        {row.name}
                        
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Box
                          sx={{
                            minWidth: 62,
                            minHeight: 30,
                            backgroundColor:row.status === 'active' ? '#E9EFF7' : '#E9EFF7',
                            display: 'inline-block',
                            textAlign: 'center', // Centers the text inside the box
                            padding: '4px 8px',
                            paddingBottom: '2px', // Optional: Adds some padding for spacing
                            fontWeight: 600,
                            borderRadius: '16px',
                            boxSizing: 'border-box',
                            color: row.status === 'active' ? '#4F63BE' : '#6B6B6B',
                          }}
                        >
                          {row.status}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', ...theme.typography.tableCellData }}>
                        {row.last_run}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                      <Box display="flex" justifyContent="flex-start" gap={1}>
                        {Object.entries(row.total_runs).map(([key, run], idx) => (
                          <Box
                            key={idx}
                            sx={{
                              color: getStatusColor(key, idx),
                              display: 'inline-block',
                              width: '3ch',
                              textAlign: 'left',
                              borderBottom: `5px solid ${getStatusColor(key, idx)}`,
                              paddingBottom: '2px',
                              fontWeight: 600,
                            }}
                          >
                            {formatRunNumber(run)}
                          </Box>
                        ))}
                      </Box>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Box
                          sx={{
                            display: 'flex', // Flex to align items in a row
                            gap: 2, // Add some spacing between the two boxes
                          }}
                        >
                          <CustomTooltip title="Edit/View">
                            <Box
                              sx={{
                                minWidth: 34,
                                minHeight: 34,
                                backgroundColor: '#E9EFF7',
                                display: 'flex', // Use flex to align items
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                textAlign: 'center',
                                padding: '2px 2px',
                                fontWeight: 600,
                                borderRadius: '40px',
                                boxSizing: 'border-box',
                                color:  '#4F63BE',
                                cursor:'pointer'
                              }}

                              onClick={() => navigate(`/workflow-builder/${row.id}`,{ state: { type: 'workflow' } })}
                            >              
                              <BorderColorOutlinedIcon sx={{ fontSize: 18 }} />
                            </Box>
                          </CustomTooltip>
                          <CustomTooltip title="Set Timer">
                            <Box
                              sx={{
                                minWidth: 34,
                                minHeight: 34,
                                backgroundColor: '#E9EFF7',
                                display: 'flex', // Use flex to align items
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                textAlign: 'center',
                                padding: '2px 2px',
                                fontWeight: 600,
                                borderRadius: '40px',
                                boxSizing: 'border-box',
                                color: '#4F63BE'
                              }}
                              
                            >              
                              <AccessTimeOutlinedIcon sx={{ fontSize: 18 }} />
                            </Box>
                          </CustomTooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '16px' }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
                
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
    </Box>
  </>
  );
};


export default MyWorkflows;



