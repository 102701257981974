import React, { useState } from 'react';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import { createCustomerApi } from '../../api/usermanagmentApi';
import { useSelector } from 'react-redux';

const CustomerForm = () => {
    const accessToken = useSelector ((state) => state.auth.accessToken);
    const [ error, setError ]= useState("");
    const [ loading, setLoading ] = useState("");
    const [CustomerData, setCustomerData] = useState({
    name: '',
    business_name: '',
    url: '',
    email_id: '',
    number_of_employees: '',
    billing_address: {
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      phone_number:''
    }
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('billing_address')) {
      const [_, field] = name.split('.');
      setCustomerData((prev) => ({
        ...prev,
        billing_address: { ...prev.billing_address, [field]: value }
      }));
    } else {
      setCustomerData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted Customer Data:', CustomerData, '  accessToken ',accessToken);
    try {
        const result = createCustomerApi(accessToken, CustomerData);
        console.log('API Response:', result);
        alert('Customer data submitted successfully!');
      } catch (error) {
        console.error('Error submitting Customer data:', error);
        setError('Failed to submit data. Please try again.');
      } finally {
        setLoading(false); // Hide loading indicator
      }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom mb={3}>
        Customer Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* General Info */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={CustomerData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Business Name"
              name="business_name"
              value={CustomerData.business_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Website URL"
              name="url"
              value={CustomerData.url}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email ID"
              name="email_id"
              value={CustomerData.email_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Number of Employees"
              type="number"
              name="number_of_employees"
              value={CustomerData.number_of_employees}
              onChange={handleChange}
            />
          </Grid>

          {/* Billing Address */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Billing Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 1"
              name="billing_address.address_line1"
              value={CustomerData.billing_address.address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="billing_address.address_line2"
              value={CustomerData.billing_address.address_line2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              name="billing_address.city"
              value={CustomerData.billing_address.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="State"
              name="billing_address.state"
              value={CustomerData.billing_address.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="billing_address.country"
              value={CustomerData.billing_address.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="ZIP Code"
              name="billing_address.zip"
              value={CustomerData.billing_address.zip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone number"
              name="billing_address.phone_number"
              value={CustomerData.billing_address.phone_number}
              onChange={handleChange}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
          <Button
                variant="contained"
                sx={{
                    alignItems:'center',
                    width: '160px',
                    height: '32px',
                    borderRadius: '24px',
                    backgroundColor: '#4F63BE',
                    boxShadow:'none',
                    textTransform: 'none',
                    '& .MuiButton-startIcon': {
                    borderRadius: '50%',
                    padding: '0px',
                    },
                }}
                onClick={handleSubmit} 
            >
            Submit
            </Button>
            {/* <Button  type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CustomerForm;
