import store from '../redux/store'
import {setAccessToken, storeTokens} from '../redux/actions/authActions';
import { jwtDecode } from "jwt-decode";
const BASE_URL = 'https://staging.runmybot.com/api';

export const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // current time in seconds
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
  // const payload = JSON.parse(atob(token.split('.')[1]));
  // return payload.exp * 1000 > Date.now();
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  // console.log('####### refreshAccessToken  ', refreshToken)
  if (!refreshToken) return null;

  const response = await fetch(`${BASE_URL}/token/refresh/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: refreshToken }),
  });
  // console.log('REFRESH TOKEN: == ',response)
  if (response.ok) {
    const data = await response.json();
    // console.log('data.access ',data.access)
    localStorage.setItem('accessToken', data.access);

    return data.access;
  }

  return null;
};

export const fetchApi = async (endpoint, { method = 'GET', headers = {}, body, requiresAuth = false } = {}) => {
  console.log('fetchApi -- endpoint ',endpoint);
  console.log(' headers ' , headers);
  try {
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    // if (requiresAuth && !isTokenValid(accessToken)) {
    //   accessToken = await refreshAccessToken();
    //   if (!accessToken) {
    //     window.alert('Session expired. Please log in again.');
    //     localStorage.removeItem('accessToken');
    //     localStorage.removeItem('refreshToken');
    //     window.location.href = '/login';
    //     return; // Stop further execution
    //   }
    //   store.dispatch(storeTokens(accessToken, refreshToken));
    //   // store.dispatch(setAccessToken(accessToken, refreshToken));
    //   // store.dispatch(storeTokens({ accessToken }));
    // }

    const authHeaders = requiresAuth
      ? { Authorization: `Bearer ${accessToken}` }
      : {};
    const combinedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    };
    console.log(' BASE_URL ' , BASE_URL , '--  endpoint ', endpoint);
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method,
      headers: combinedHeaders,
      body: body ? JSON.stringify(body) : undefined,
    });
    const contentType = response.headers.get('Content-Type');

    
    if (!response.ok) {
      if(endpoint=='logout/'){
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href='/login'
      }
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Request failed with status ${response.status}`);
      } else {
        const errorText = await response.text();
        throw new Error(errorText || `Request failed with status ${response.status}`);
      }
    }
    
    const text = await response.text();
    return text ? JSON.parse(text) : {};
  } catch (error) {
    console.error(`Error in fetchApi: ${error.message}`);
    throw error;
  }
};